import Logo1 from "../../assets/brands/1.png";
import Logo2 from "../../assets/brands/2.png";
import Logo3 from "../../assets/brands/3.png";
import Logo4 from "../../assets/brands/4.png";
import Logo5 from "../../assets/brands/5.png";
import Logo6 from "../../assets/brands/6.png";
import Logo7 from "../../assets/brands/7.png";
import Logo8 from "../../assets/brands/8.png";
import Logo9 from "../../assets/brands/9.png";
import Logo10 from "../../assets/brands/10.png";
import Logo11 from "../../assets/brands/11.png";
import Logo12 from "../../assets/brands/12.png";
import Logo13 from "../../assets/brands/13.png";
import Logo14 from "../../assets/brands/14.png";
import Logo15 from "../../assets/brands/15.png";
import Logo16 from "../../assets/brands/16.png";
import Logo17 from "../../assets/brands/17.png";
import Logo18 from "../../assets/brands/18.png";
import Logo19 from "../../assets/brands/19.png";
import Logo20 from "../../assets/brands/20.png";
import Logo21 from "../../assets/brands/21.png";

function ECommerceBrands() {
  return (
    <section className="z-10 bg-[#ffffff] ">
      <div className="mx-10 lg:mx-40 py-8 md:py-20">
        <div className="relative flex pb-8 md:pb-16 items-center text-center justify-center ">
          <div className="hidden lg:block flex-grow border-t border-gray-900"></div>
          <span className="text-[24px] md:text-[40px] leading-2 md:leading-10 mx-4   ">
            <span className="text-black font-bold"> The Choice of </span>

            <span className="text-[#FF7927] md:text-black font-extrabold md:font-bold ">
              {" "}
              Top E-commerce Brands
            </span>
          </span>
          <div className="hidden lg:block flex-grow border-t border-gray-900"></div>
        </div>
        <div className="flex overflow-hidden space-x-8 md:space-x-16 group">
          <div className="flex space-x-8 md:space-x-16 animate-loop-scroll group-hover:paused">
            <img
              loading="lazy"
              src={Logo1}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo1"
            />
            <img
              loading="lazy"
              src={Logo2}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo2"
            />
            <img
              loading="lazy"
              src={Logo3}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo3"
            />
            <img
              loading="lazy"
              src={Logo4}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo4"
            />
            <img
              loading="lazy"
              src={Logo5}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo5"
            />
            <img
              loading="lazy"
              src={Logo6}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo6"
            />
            <img
              loading="lazy"
              src={Logo7}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo7"
            />

            <img
              loading="lazy"
              src={Logo8}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo8"
            />
            <img
              loading="lazy"
              src={Logo9}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo9"
            />
            <img
              loading="lazy"
              src={Logo10}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo10"
            />
            <img
              loading="lazy"
              src={Logo11}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo11"
            />
            <img
              loading="lazy"
              src={Logo12}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo12"
            />

            <img
              loading="lazy"
              src={Logo13}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo13"
            />
            <img
              loading="lazy"
              src={Logo14}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo14"
            />
            <img
              loading="lazy"
              src={Logo15}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo15"
            />
            <img
              loading="lazy"
              src={Logo16}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo16"
            />
            <img
              loading="lazy"
              src={Logo17}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo17"
            />
            <img
              loading="lazy"
              src={Logo18}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo18"
            />

            <img
              loading="lazy"
              src={Logo19}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo19"
            />
            <img
              loading="lazy"
              src={Logo20}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo20"
            />
            <img
              loading="lazy"
              src={Logo21}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo21"
            />
          </div>
          <div
            className="flex space-x-8 md:space-x-16 animate-loop-scroll group-hover:paused"
            aria-hidden="true"
          >
            <img
              loading="lazy"
              src={Logo1}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo1"
            />
            <img
              loading="lazy"
              src={Logo2}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo2"
            />
            <img
              loading="lazy"
              src={Logo3}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo3"
            />
            <img
              loading="lazy"
              src={Logo4}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo4"
            />
            <img
              loading="lazy"
              src={Logo5}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo5"
            />
            <img
              loading="lazy"
              src={Logo6}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo6"
            />
            <img
              loading="lazy"
              src={Logo7}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo7"
            />

            <img
              loading="lazy"
              src={Logo8}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo8"
            />
            <img
              loading="lazy"
              src={Logo9}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo9"
            />
            <img
              loading="lazy"
              src={Logo10}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo10"
            />
            <img
              loading="lazy"
              src={Logo11}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo11"
            />
            <img
              loading="lazy"
              src={Logo12}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo12"
            />

            <img
              loading="lazy"
              src={Logo13}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo13"
            />
            <img
              loading="lazy"
              src={Logo14}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo14"
            />
            <img
              loading="lazy"
              src={Logo15}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo15"
            />
            <img
              loading="lazy"
              src={Logo16}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo16"
            />
            <img
              loading="lazy"
              src={Logo17}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo17"
            />
            <img
              loading="lazy"
              src={Logo18}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo18"
            />

            <img
              loading="lazy"
              src={Logo19}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo19"
            />
            <img
              loading="lazy"
              src={Logo20}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo20"
            />
            <img
              loading="lazy"
              src={Logo21}
              className="max-w-none max-h-[25px]  md:max-h-[50px]  "
              alt="Logo21"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ECommerceBrands;
