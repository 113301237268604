import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import MarketingCampaignsImage from "../../assets/images/MarketingCampaigns.png";
import ContentDetail from "../banners/contentDetail";
import HowItWorks from "../banners/howItWorks";
import { useLayoutEffect } from "react";

function MarketingCampaigns() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <div className="bg-[#F5F5FC]">
        <section className="mx-10 lg:mx-40 py-10 md:py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="text-[26px]  xl:text-[36px] 2xl:text-[48px] text-[#1E1E1E] font-bold">
              MARKETING CAMPAIGNS
            </div>
            <div className="flex justify-start md:text-left md:justify-end text-[16px] xl:text-xl text-[#1E1E1E] font-normal">
              <p className="max-w-full md:max-w-[490px]">
                Maximize your brand's visibility with expertly managed PPC and
                meta campaigns, and develop a robust marketing strategy tailored
                to your unique needs.
              </p>
            </div>
          </div>
        </section>
      </div>
      <img
        className="object-fill h-4/5 w-full"
        alt="MarketingCampaignsImage"
        src={MarketingCampaignsImage}
      />
      <ContentDetail
        margin={true}
        title="PPC Campaigns"
        id="ppc-campaigns"
        detail="Increase your brand’s reach and visibility across Amazon with our expertly managed Pay-Per-Click (PPC) campaigns. We use data-driven strategies to improve and optimize your ad spend and enhance click-through dates, and achieve maximum sales."
      />
      <ContentDetail
        margin={true}
        title="Meta Campaigns"
        id="meta-campaigns"
        detail="Maximize the use of Meta Campaigns and ensure that you attain optimal impressions, reach, and enagement on platforms such as Facebook and Instagram. Our team of marketing experts leverages multiple ad platforms to ensure that your brand stands out from the crowd and gets the recognition that it deserves."
      />
      <ContentDetail
        margin={true}
        title="Marketing Strategy"
        id="marketing-strategy"
        detail="Craft a robust and effective marketing strategy tailored according to your brand’s unique needs and preferences. From market analysis to execution, our team provides expert guidnace and actionable plans to enhance your brand’s presence and performance across Amazon."
      />
      <HowItWorks eCommerceBrands={false} />
      <ECommerceBrands />
      <WantToLearnMore />

      <Footer />
    </div>
  );
}

export default MarketingCampaigns;
