import AverageRatings from "../../assets/gifs/01.gif";
import Brands from "../../assets/gifs/02.gif";
import CustomerRating from "../../assets/gifs/03.gif";
import ProductCategories from "../../assets/gifs/04.gif";
function ExponentialGrowth() {
  return (
    <section className="mx-10 lg:mx-40 ">
      <p className="my-5 text-normal md:text-base uppercase font-normal text-[#000000]">
        EXPONENTIAL GROWTH
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
        <div className="text-[40px] text-[#1E1E1E] font-bold">
          Your brand. Our reach.
        </div>
        <div className="flex mt-4 md:mt-0 md:justify-end text-xl text-[#1E1E1E] font-normal">
          <p className="max-w-[490px]">
            We don't just talk the talk; we walk the walk. We’re proving our
            success through facts and figures.{" "}
          </p>
        </div>
      </div>
      <div className="my-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-4">
        <div className="border-l-2 border-b-2 md:border-b-0 pt-16 pb-6 px-10 border-[#00000073]">
          <img
            loading="lazy"
            src={AverageRatings}
            className="max-w-20"
            alt="AverageRatings"
          />
          <p className="sm:text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[54px] text-[54px] text-[#FF7927] font-bold">
            4.9 Stars
          </p>
          <p className="text-base sm:text-sm xl:text-base text-[#1E1E1E] font-normal">
            High <span className="font-bold"> average rating </span>
            over time for sellers or products on Amazon.
          </p>
        </div>
        <div className="border-l-2 border-b-2 md:border-b-0 pt-16 pb-6 px-10 border-[#00000073]">
          <img loading="lazy" src={Brands} className="max-w-20" alt="Brands" />
          <p className="sm:text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[54px] text-[54px] text-[#FF7927] font-bold">
            1800+
          </p>
          <p className="text-base sm:text-sm xl:text-base text-[#1E1E1E] font-normal">
            Positive <span className="font-bold"> customer ratings </span> on
            Amazon, showing satisfaction.
          </p>
        </div>

        <div className="border-l-2 border-b-2 sm:border-b-0  pt-16 pb-6 px-10 border-[#00000073]">
          <img
            loading="lazy"
            src={ProductCategories}
            className="max-w-20"
            alt="ProductCategories"
          />
          <p className="sm:text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[54px] text-[54px] text-[#FF7927] font-bold">
            14+
          </p>
          <p className="text-base sm:text-sm xl:text-base text-[#1E1E1E] font-normal">
            High-selling <span className="font-bold"> product categories </span>{" "}
            that dominate the digital marketplace.
          </p>
        </div>
        <div className="border-l-2   pt-16 pb-6 px-10 border-[#00000073]">
          <img
            loading="lazy"
            src={CustomerRating}
            className="max-w-20"
            alt="CustomerRating"
          />
          <p className="sm:text-[28px] lg:text-[32px] xl:text-[36px] 2xl:text-[54px] text-[54px] text-[#FF7927] font-bold">
            21+
          </p>
          <p className="text-base sm:text-sm xl:text-base text-[#1E1E1E] font-normal">
            Esteemed <span className="font-bold"> brands </span>place their
            trust in our exceptional services.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ExponentialGrowth;
