function OurStory() {
  return (
    <section className="pt-20 bg-[#F5F5FC] ">
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <div className=" mx-10 lg:mx-40 ">
        <p className="my-4 md:my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
          Our Story
        </p>
        <div className=" grid grid-cols-1 md:gap-8 md:grid-cols-7">
          <div className="md:content-center text-white md:col-span-4">
            <div className="block md:hidden pb-4 content-center text-white md:col-span-3 ">
              {" "}
              <img
                src="https://i.ibb.co/ys2gddZ/OURSTORY.png"
                alt="OURSTORY"
                className="w-full h-full rounded-3xl col-span-2"
              />
            </div>
            <div className="text-[24px] lg:text-[32px] xl:text-[40px] text-[#1E1E1E] font-bold">
              Who Are We
            </div>
            <div className="mt-4 lg:mt-8 text-[14px] lg:text-[18px] xl:text-[20px] text-[#1E1E1E] font-normal">
              <p className="">
                Stockyfy story goes beyond mere products; it's about a
                commitment, a dedication to quality, innovation, and above all:
                customer satisfaction.
              </p>
              <p className="mt-2 md:mt-4">
                {" "}
                Since 2016, we have been making history in the e-commerce
                marketplace. With our front-end operations in the heart of
                Seattle, North America, we've become a driving force of
                innovation and excellence. Trusted by businesses and resellers
                alike, Stockyfy has earned its place as a source of reliability
                in an ever-changing market.
              </p>
            </div>
          </div>
          <div className="hidden md:block pb-4 content-center text-white md:col-span-3 ">
            {" "}
            <img
              src="https://i.ibb.co/ys2gddZ/OURSTORY.png"
              alt="OURSTORY"
              className="w-full h-full rounded-3xl col-span-2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurStory;
