import { useLayoutEffect } from "react";
import ContentDetailWithLeftImage from "../banners/contentDetailWithLeftImage";
import ContentDetailWithRightImage from "../banners/contentDetailWithRightImage";
import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import {
  sellerCentralManagementDetails1,
  sellerCentralManagementDetails2,
  sellerCentralManagementDetails3,
  sellerCentralManagementDetails4,
} from "../../containers/staticsData/statics";

function SellerCentralManagement() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <div className="bg-[#F5F5FC]">
        <section className="pt-10 pb-10 md:pt-20 md:pb-40">
          <div className="flex justify-center text-center text-[24px] md:text-[56px] xl:text-[96px] text-[#1E1E1E] font-bold">
            Seller Central Management
          </div>
          <ContentDetailWithRightImage
            details={sellerCentralManagementDetails1}
          />
          <ContentDetailWithLeftImage
            details={sellerCentralManagementDetails2}
          />
          <ContentDetailWithRightImage
            details={sellerCentralManagementDetails3}
          />
          <ContentDetailWithLeftImage
            details={sellerCentralManagementDetails4}
          />
        </section>
        <ECommerceBrands />
        <WantToLearnMore />
        <Footer />
      </div>
    </div>
  );
}

export default SellerCentralManagement;
