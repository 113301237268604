function ContentDetail(props) {
  return (
    <div id={props.id} className={`${props.margin ? "px-10 lg:px-40" : ""} `}>
      <div
        className={`${props.margin ? "mt-6 md:mt-20" : "mt-6"}  items-center`}
      >
        <hr className="h-0.5 bg-[#DEDEF6]" />
      </div>
      <div
        className={`${
          props.margin ? "text-[24px] md:text-[32px]" : "text-lg"
        } pt-2 pb-2 md:pt-6 md:pb-5 text-[#000000] font-semibold `}
      >
        {props.title}
      </div>
      <div
        className={`${
          props.margin ? "text-sm md:text-xl" : "text-xs md:text-sm"
        }  text-[#1E1E1E] font-normal`}
      >
        {props.detail}
      </div>
    </div>
  );
}

export default ContentDetail;
