function HowItWorks(props) {
  return (
    <section
      className={`bg-[${props.eCommerceBrands ? "#ffffff" : "#F5F5FC"}] ${
        props.eCommerceBrands ? "" : "md:mt-20"
      } py-8 md:py-20 `}
    >
      <div className="py-5 mx-10 xl:mx-40 ">
        <div className="block md:hidden  items-center">
          <hr className="z-10 h-0.5 bg-[#000000]" />
        </div>
        {/* <div className="flex-grow border-t border-gray-900"></div> */}
        <div className=" flex items-start justify-start md:items-center md:justify-center ">
          <p className="mt-2 md:mt-0 text-[12px] md:text-base uppercase  text-[#1E1E1E] font-inter font-normal">
            HOW IT WORKS
          </p>
        </div>
        <div className=" flex  items-start justify-start md:items-center md:justify-center md:text-center ">
          <p className="md:max-w-[630px] text-[24px] md:text-[40px] text-[#1E1E1E] tracking-wider font-bold">
            We'll take you everywhere 
            <span className="text-[#FF7927]">you want to grow.</span>
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-3">
          <div className="mx-2 md:ml-6 xl:ml-8 2xl:ml-10 pr-2 pl-6 md:pl-0 border-b-2 md:border-b-0 border-l-2 md:border-l-0 md:border-r-2 text-[#111111] pt-6 md:pt-16 border-[#DEDEF6]">
            <div className="font-semibold text-xl ">01</div>
            <p className="my-8 max-w-[200px] font-semibold text-xl">
              We Buy Your Inventory
            </p>
            <p className=" my-6 text-xs max-w-[300px] font-normal">
              After we’ve gotten to know each other, we’ll place an order for
              all the inventory that we are going to sell in the first few
              months. And as we sell, we’ll keep adding and buying more.
            </p>
            <div className="block md:hidden relative">
              <svg
                className="absolute bottom-[-5px] left-[-30px]"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <circle cx="4.63867" cy="5.02246" r="4.30078" fill="#DEDEF6" />
              </svg>
            </div>
          </div>
          <div className="mx-2 md:ml-6 xl:ml-8 2xl:ml-10 pr-2 pl-6 md:pl-0 border-b-2 md:border-b-0  border-l-2 md:border-l-0 md:border-r-2 pt-6 md:pt-16 border-[#DEDEF6]">
            <div className=" font-semibold text-xl ">02</div>
            <p className="my-8 max-w-[200px] font-semibold text-xl">
              We Do More Than Just “Buying”
            </p>
            <p className="my-6 text-xs max-w-[300px] font-normal">
              Placing an order for your inventory is just a small part of what
              we do. We forecast, create Amazon-winning content and strategies,
              provide exceptional customer service, look for opportunities to
              expand in the global marketplace, and much more.
            </p>
            <div className="block md:hidden relative">
              <svg
                className="absolute bottom-[-5px] left-[-30px]"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <circle cx="4.63867" cy="5.02246" r="4.30078" fill="#DEDEF6" />
              </svg>
            </div>
            <div className="hidden md:block relative">
              <svg
                className="absolute bottom-[-21px] md:left-[-38px] xl:left-[-45px] 2xl:left-[-54px]"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <circle cx="4.63867" cy="5.02246" r="4.30078" fill="#DEDEF6" />
              </svg>
            </div>
            <div className="hidden md:block relative">
              <svg
                className="absolute bottom-[-21px] right-[-13px]"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <circle cx="4.63867" cy="5.02246" r="4.30078" fill="#DEDEF6" />
              </svg>
            </div>
          </div>

          <div className="mx-2 md:ml-6 xl:ml-8 2xl:ml-10 pr-2 pl-6 md:pl-0 border-b-2 md:border-b-0 border-l-2 md:border-l-0 pt-6 md:pt-16">
            <div className=" font-semibold text-xl ">03</div>
            <p className="my-8 max-w-[230px] font-semibold text-xl">
              We Identify Growth Opportunities
            </p>
            <p className=" my-6 text-xs max-w-[300px] font-normal">
              We dive deep into the heart of your business, researching what
              makes it unique and identifying opportunities for growth. Whether
              it's streamlining your inventory, improving customer experience,
              or expanding your reach, we're here to help you discover the
              untapped potential in your brand.
            </p>
            <div className="block md:hidden relative">
              <svg
                className="absolute bottom-[-5px] left-[-30px]"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <circle cx="4.63867" cy="5.02246" r="4.30078" fill="#DEDEF6" />
              </svg>
            </div>
          </div>
        </div>
        <div className="hidden md:block items-center">
          <hr className="z-10 h-0.5 bg-[#DEDEF6]" />
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
