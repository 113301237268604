import React, { useRef, useEffect, useState } from "react";

const OurJourneyScroll = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cardRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  // Function to handle scroll events
  const handleScroll = () => {
    // const scrollTop = window.scrollY;
    const cardElements = cardRefs.current;
    console.log("🚀 ~ handleScroll ~ cardElements:", cardElements);

    // Find the active card based on scroll position
    let activeIndex = cardElements.findIndex((cardRef, index) => {
      if (cardRef.current) {
        const top = cardRef.current.getBoundingClientRect().top;
        return top >= 0 && top <= window.innerHeight / 2; // Adjust as needed
      }
      return false;
    });
    console.log("🚀 ~ activeIndex ~ activeIndex:", activeIndex);

    setActiveCard(activeIndex !== -1 ? activeIndex : 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="">
      <div className="grid grid-cols-2">
        <div
          className={`border-r-[6px] pt-8 border-[${
            activeCard >= 0 ? "#FF7927" : "#F5F5FC"
          }] pr-2 md:pr-10  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[0]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 0 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300 `}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 0 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2016
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              Initiating Amazon Wholesale Operations
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              We launched as an Amazon Wholesale business in Seattle,
              Washington, initiating our journey with Garmin.
            </p>
          </div>
        </div>
        <div
          className={`border-l-[6px] border-[${
            activeCard >= 1 ? "#FF7927" : "#F5F5FC"
          }] mt-48 ml-[-6px] pl-2 md:pl-10 transition ease-in-out delay-50 duration-600 `}
        >
          <div
            ref={cardRefs.current[1]}
            className={`p-4 md:p-10 rounded-[10px]  ${
              activeCard === 1 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            }  transition ease-in-out delay-150 duration-300 `}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 1 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2017
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              Dominating the Consumer Electronics Market
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              By becoming authorized distributors for Plantronics, we marked a
              significant expansion and gained a strong place in the electronics
              market.
            </p>
          </div>
        </div>
        <div
          className={`border-r-[6px] border-[${
            activeCard >= 2 ? "#FF7927" : "#F5F5FC"
          }] pr-2 md:pr-10 mt-[-60px]  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[2]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 2 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300 `}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 2 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2018
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              Stockyfy grew into a team of 50
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              We onboarded specialised experts each playing a crucial role in
              our success story.
            </p>
          </div>
        </div>
        <div
          className={`border-l-[6px] border-[${
            activeCard >= 3 ? "#FF7927" : "#F5F5FC"
          }] mt-24 ml-[-6px] pl-2 md:pl-10  transition ease-in-out delay-550 duration-600`}
        >
          <div
            ref={cardRefs.current[3]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 3 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            }  transition ease-in-out delay-150 duration-300`}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 3 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2019
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              Strengthening Our Network with Premier Fortune 500 Collaborations
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              In this landmark year, we initiated associations with reputable
              brands like Tech Data, Ingram Micro, and D&H.
            </p>
          </div>
        </div>
        <div
          className={`border-r-[6px] border-[${
            activeCard >= 4 ? "#FF7927" : "#F5F5FC"
          }] pr-2 md:pr-10 mt-[-60px]  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[4]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 4 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300`}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 4 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2020
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              A Remarkable 3x Growth
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              Amid the challenges of COVID-19, by diversifying into categories
              like tools, toys and games, and sports, and partnering with
              leading brands like Taramps and Ryobi we were able to witness
              incredible growth.
            </p>
          </div>
        </div>
        <div
          className={`border-l-[6px] border-[${
            activeCard >= 5 ? "#FF7927" : "#F5F5FC"
          }] mt-24 ml-[-6px] pl-2 md:pl-10  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[5]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 5 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300 `}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 5 ? "#FF7927" : "#FF7927"
              }] font-bold transition ease-in-out delay-150 duration-300`}
            >
              2021
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              We Continued to Soar with Industry Titans
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              Welcomed new associations with industry giants like Stanley and
              Blackvue.
            </p>
          </div>
        </div>
        <div
          className={`border-r-[6px] border-[${
            activeCard >= 6 ? "#FF7927" : "#F5F5FC"
          }] pr-2 md:pr-10 mt-[-60px]  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[6]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 6 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300`}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 6 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2022
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              A Step into Network Solutions
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              Our expansion into network categories brought us valuable
              partnerships with TP LINK, Aruba Network (an HP subsidiary),
              Fortinet, and Mikrotik
            </p>
          </div>
        </div>
        <div
          className={`border-l-[6px] border-[${
            activeCard >= 7 ? "#FF7927" : "#F5F5FC"
          }] pl-2 md:pl-10  mt-24 ml-[-6px]  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[7]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 7 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300`}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 7 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2023
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              Accelerating & Expanding with Esteemed Industry Leaders
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              Our associations continued to grow with the onboarding of
              reputable brands like Blackmagic Design, Star Micronics, and
              Mitutuyo.
            </p>
          </div>
        </div>
        <div
          className={`border-r-[6px] border-[${
            activeCard >= 8 ? "#FF7927" : "#F5F5FC"
          }] pr-2 md:pr-10 mt-[-60px]  transition ease-in-out delay-50 duration-600`}
        >
          <div
            ref={cardRefs.current[8]}
            className={`p-4 md:p-10 rounded-[10px] ${
              activeCard === 8 ? "shadow-lg bg-[#F5F5FC]" : "border-2 bg-[#Fff]"
            } transition ease-in-out delay-150 duration-300`}
          >
            <p
              className={`text-[16px] md:text-[32px] mb-2 md:mb-6 text-[${
                activeCard === 8 ? "#FF7927" : "#FF7927"
              }] font-bold  transition ease-in-out delay-150 duration-300`}
            >
              2024
            </p>
            <p className="text-[14px] md:text-[20px] mb-3 text-[#000000] font-semibold">
              A Year of Growth and Partnership
            </p>
            <p className="text-[10px] md:text-[14px] text-[#000000] font-normal">
              A milestone year, celebrating a diverse team of 200 employees and
              the addition of new partners like Boya, Joyroom, Ikko, and 8bitdo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurJourneyScroll;
