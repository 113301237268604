import ReachUsNow from "../banners/reachUsNow";
import Footer from "../footer/footer";
import Header from "../header/header";
import ContactUsHeroSection from "../Hero/ContactUsHeroSection";

function ContactUsPage() {
  return (
    <div>
      <div className="relative bg-cover bg-center  bg-[url('https://i.ibb.co/7JfKgGz/contactus.png')] bg-no-repeat">
        <Header background={true} />
        <ContactUsHeroSection />
      </div>
      <ReachUsNow />
      <Footer />
    </div>
  );
}

export default ContactUsPage;
