import { HashLink } from "react-router-hash-link";
import ContentDetail from "./contentDetail";

function ContentDetailWithRightImage(props) {
  return (
    <section className=" mx-10 lg:mx-40 pt-10 md:pt-20 ">
      <div className="p-4 bg-white rounded-3xl grid grid-cols-1 xl:grid-cols-7">
        <div className="block xl:hidden p-4 text-white xl:col-span-3 ">
          {" "}
          <img
            src={props.details.mobileImage}
            alt="Our Services"
            className="w-full h-full rounded-3xl"
          />
        </div>
        <div className="px-4 pt-4 md:px-10 md:pt-10 text-white xl:col-span-4">
          <div className="grid grid-col-1 md:grid-cols-3 md:gap-4 items-center">
            <div className="mb-2 md:mb-0 text-[24px] text-[#1E1E1E] font-bold">
              {props.details.title}
            </div>
            <div className="flex md:col-span-2 justify-end text-sm text-[#1E1E1E] font-normal">
              <p className="md:max-w-[400px]">{props.details.description} </p>
            </div>
          </div>
          {props.details.lists.map((item, index) => (
            <ContentDetail
              key={index}
              margin={false}
              title={item.title}
              id={item.id}
              detail={item.description}
            />
          ))}
          <HashLink smooth to="/contact">
            <button
              className={`transition-all duration-500 mt-5 md:mt-10 bg-transparent hover:bg-[#FF7927] hover:border-[#FF7927] border border-[#000000] font-manrope text-base font-normal leading-6 text-[#000000] hover:text-[#ffffff] uppercase py-2.5 sm:w-auto w-full sm:px-7 rounded-full flex justify-center`}
            >
              <span className="mr-2">Schedule a Meeting</span>
            </button>
          </HashLink>
        </div>
        <div className="xl:block hidden p-4 text-white xl:col-span-3 ">
          {" "}
          <img
            src={props.details.image}
            alt="Our Services"
            className="w-full rounded-3xl col-span-2"
          />
        </div>
      </div>
    </section>
  );
}

export default ContentDetailWithRightImage;
