import { Link, useLocation } from "react-router-dom";
import StockyfyLogoWhite from "../../assets/logos/StockyfyLogoWhite.svg";
import Facebook from "../../assets/svgs/Facebook.svg";
import Instagram from "../../assets/svgs/Instagram.svg";
import Twitter from "../../assets/svgs/Twitter.svg";

function Footer() {
  const location = useLocation();
  return (
    <section className="w-full bg-[#1e1e1e] py-16 ">
      <div className=" mx-auto  max-w-full">
        <div className="px-10 lg:px-24 grid grid-cols-2 md:gap-10 mb-3 sm:grid-cols-3 md:grid-cols-12 lg:gap-20">
          <div className="col-span-4">
            <Link to="/">
              <span className=" sr-only">Stockyfy</span>
              <img
                className="h-12 w-auto"
                src={StockyfyLogoWhite}
                alt="Stockify"
              />
            </Link>
            <p className="my-4 max-w-sm font-manrope text-base font-normal leading-6 text-[#ffffff]">
              Our story goes way beyond selling products. Since 2016, we have
              helped brands grow on Amazon with every click and customer review
              - and we’re just getting started.
            </p>
            <div className="my-6 block md:hidden items-center">
              <hr className="z-10 h-0.5 bg-[#000000]" />
            </div>
          </div>

          <nav className="md:mt-4 col-span-4 md:col-span-4 lg:col-span-4">
            <p className="mb-3 text-sm font-semibold tracking-wider text-[#ffffff8F]">
              Important Links
            </p>
            <Link
              to="/e-commerce-marketplace-management"
              className={`flex mb-3 text-[15px] capitalize font-medium ${
                location.pathname === "/e-commerce-marketplace-management"
                  ? "text-gray-400"
                  : "text-white"
              } transition hover:text-gray-400 md:mb-2`}
            >
              E-Commerce Marketplace Management
            </Link>
            <Link
              to="/end-to-end-logistics-management"
              className={`flex mb-3 text-[15px] capitalize font-medium ${
                location.pathname === "/end-to-end-logistics-management"
                  ? "text-gray-400"
                  : "text-white"
              } transition hover:text-gray-400 md:mb-2`}
            >
              End-to-End Logistics Management
            </Link>
            <Link
              to="/marketing-campaigns"
              className={`flex mb-3 text-[15px] capitalize font-medium ${
                location.pathname === "/marketing-campaigns"
                  ? "text-gray-400"
                  : "text-white"
              } transition hover:text-gray-400 md:mb-2`}
            >
              Marketing Campaigns
            </Link>
            <Link
              to="/on-demand-service"
              className={`flex mb-3 text-[15px] capitalize font-medium ${
                location.pathname === "/on-demand-service"
                  ? "text-gray-400"
                  : "text-white"
              } transition hover:text-gray-400 md:mb-2`}
            >
              On-Demand Services
            </Link>
            <div className="my-6 block md:hidden items-center">
              <hr className="z-10 h-0.5 bg-[#000000]" />
            </div>
          </nav>
          <nav className="md:mt-4 col-span-4 md:col-span-4 lg:col-span-4">
            <p className="mb-3 text-sm font-semibold tracking-wider text-[#ffffff8F]">
              Contact Us
            </p>
            <Link
              to="/"
              className="grid grid-cols-12 mb-3 text-[15px] capitalize font-medium text-white transition hover:text-gray-400 md:mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 41 41"
                fill="none"
                className="mr-2 col-span-1 md:col-span-2 2xl:col-span-1"
              >
                <path
                  d="M40.3201 21.2807C40.2441 21.8804 40.1791 22.4825 40.0891 23.0804C39.3733 27.838 37.2055 31.8205 33.6311 35.0251C30.7707 37.59 27.4239 39.2039 23.6271 39.7798C17.9848 40.6361 12.8348 39.3682 8.31465 35.8617C4.03487 32.5427 1.50427 28.1486 0.787301 22.7785C-0.587431 12.4791 6.22992 2.91285 16.4318 0.766576C17.2754 0.588929 18.1433 0.529133 18.9996 0.411863C19.1262 0.394446 19.2504 0.363677 19.3752 0.339294C20.0991 0.339294 20.8231 0.339294 21.547 0.339294C21.6591 0.363097 21.7705 0.400832 21.8837 0.40896C25.8837 0.694588 29.4808 2.05771 32.6279 4.53432C36.798 7.81498 39.2978 12.0924 40.0937 17.3493C40.182 17.9339 40.2452 18.5226 40.3195 19.1089V21.2807H40.3201ZM2.08714 20.2067C2.09469 30.3146 10.3286 38.5491 20.4452 38.5665C30.5612 38.5833 38.8304 30.325 38.8357 20.2009C38.8403 10.0774 30.5832 1.81969 20.4591 1.82259C10.3332 1.82607 2.08018 10.0849 2.08772 20.2067H2.08714Z"
                  fill="#FF7927"
                />
                <path
                  d="M17.1892 18.5546C18.4739 20.5406 20.1076 22.172 22.1209 23.4788C22.4379 23.0283 22.752 22.5853 23.0632 22.14C23.6269 21.3342 24.0147 21.2118 24.936 21.538C26.0077 21.9177 26.9818 22.4779 27.9003 23.1397C28.3061 23.4318 28.482 23.8422 28.3989 24.3328C28.2741 25.066 28.1394 25.7986 27.9763 26.5243C27.7307 27.6163 26.7711 28.241 25.6594 28.1261C19.5758 27.4944 14.132 22.3531 12.8078 16.5958C12.6784 16.0333 12.586 15.4585 12.5257 14.8838C12.4171 13.8522 13.0545 12.9384 14.0577 12.6992C14.8113 12.5198 15.5759 12.3846 16.3399 12.2557C16.8176 12.175 17.2211 12.3526 17.5068 12.7468C18.2202 13.732 18.8107 14.7834 19.1944 15.9433C19.4075 16.5865 19.2548 17.0649 18.7021 17.462C18.215 17.8121 17.7221 18.154 17.2322 18.5C17.224 18.5058 17.2194 18.5163 17.1892 18.5552V18.5546Z"
                  fill="#FF7927"
                />
              </svg>

              <span className="mt-1.5 ml-4 sm:ml-0 col-span-11 md:col-span-10 2xl:col-span-11">
                (425) 654-0681
              </span>
            </Link>
            <Link
              to="/"
              className="grid grid-cols-12 mb-3 text-[15px] capitalize font-medium text-white transition hover:text-gray-400 md:mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 41 40"
                fill="none"
                className="mr-2 col-span-1 md:col-span-2 2xl:col-span-1"
              >
                <path
                  d="M40.3195 21.0353C40.2435 21.635 40.1785 22.237 40.0885 22.835C39.3727 27.5925 37.2049 31.5751 33.6305 34.7797C30.7701 37.3445 27.4233 38.9585 23.6265 39.5344C17.9842 40.3907 12.8342 39.1227 8.31407 35.6163C4.03429 32.2967 1.50369 27.9025 0.787301 22.5325C-0.58743 12.233 6.22991 2.66682 16.4318 0.520544C17.2754 0.342897 18.1433 0.2831 18.9996 0.16583C19.1256 0.148414 19.2498 0.117645 19.3752 0.0932617C20.0991 0.0932617 20.8231 0.0932617 21.547 0.0932617C21.6591 0.117064 21.7705 0.1548 21.8837 0.162928C25.8837 0.448556 29.4808 1.81168 32.6279 4.28828C36.798 7.56895 39.2978 11.8464 40.0937 17.1032C40.1819 17.6879 40.2452 18.2765 40.3195 18.8629V21.0347V21.0353ZM2.08656 19.9618C2.09411 30.0697 10.328 38.3042 20.4446 38.3216C30.5606 38.3384 38.8299 30.0802 38.8351 19.956C38.8397 9.83249 30.5826 1.57481 20.4585 1.57772C10.3326 1.5812 2.0796 9.84004 2.08714 19.9618H2.08656Z"
                  fill="#FF7927"
                />
                <path
                  d="M29.449 15.3134V15.7151C29.449 18.5383 29.4496 21.3615 29.449 24.1853C29.449 25.4909 28.6566 26.2857 27.3538 26.2863C22.7553 26.2874 18.1568 26.2874 13.5578 26.2863C12.2788 26.2863 11.4823 25.4834 11.4817 24.1992C11.4811 21.3545 11.4771 18.5099 11.4875 15.6652C11.4887 15.343 11.57 15.0214 11.624 14.6289C11.9642 14.8634 12.2254 15.0405 12.4838 15.2211C14.9284 16.9284 17.3789 18.6283 19.8114 20.3525C20.2613 20.6718 20.6224 20.6828 21.0915 20.3937C23.7742 18.7397 26.4726 17.1102 29.1657 15.473C29.2377 15.4295 29.3126 15.39 29.449 15.3134Z"
                  fill="#FF7927"
                />
                <path
                  d="M28.7706 14.0727C25.9921 15.7627 23.2467 17.4329 20.4943 19.1067C17.9353 17.3192 15.3873 15.5392 12.8398 13.7598C12.8404 13.7331 12.841 13.7064 12.8416 13.6797C13.0314 13.6536 13.2207 13.6042 13.4105 13.6042C18.1158 13.6002 22.8212 13.5996 27.5265 13.6031C27.9758 13.6031 28.3863 13.7384 28.7706 14.0733V14.0727Z"
                  fill="#FF7927"
                />
              </svg>
              <span className="lowercase mt-1.5 ml-4 sm:ml-0 col-span-11 md:col-span-10 2xl:col-span-11">
                partnerships@stockyfy.com
              </span>
            </Link>

            <Link className="grid grid-cols-12 text-[15px] capitalize font-medium mb-3 text-white transition hover:text-gray-400 md:mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                min-width="35px"
                min-height="35px"
                viewBox="0 0 41 40"
                fill="none"
                className="mr-2 col-span-1 md:col-span-2 2xl:col-span-1"
              >
                <path
                  d="M40.3195 21.2246C40.2435 21.8243 40.1785 22.4263 40.0885 23.0243C39.3727 27.7819 37.2049 31.7644 33.6305 34.969C30.7702 37.5339 27.4233 39.1478 23.6265 39.7237C17.9842 40.58 12.8342 39.3121 8.31407 35.8056C4.03429 32.486 1.50369 28.0919 0.787301 22.7218C-0.58743 12.4224 6.22991 2.85615 16.4318 0.709873C17.2754 0.532226 18.1433 0.472431 18.9996 0.355161C19.1256 0.337745 19.2498 0.306976 19.3752 0.282593C20.0991 0.282593 20.8231 0.282593 21.547 0.282593C21.6591 0.306395 21.7705 0.344128 21.8837 0.352255C25.8837 0.637884 29.4808 2.001 32.6279 4.47761C36.798 7.75827 39.2978 12.0357 40.0937 17.2926C40.1819 17.8772 40.2452 18.4659 40.3195 19.0522V21.224V21.2246ZM2.08656 20.1512C2.09411 30.2591 10.328 38.4935 20.4446 38.5109C30.5606 38.5278 38.8299 30.2695 38.8351 20.1454C38.8397 10.0218 30.5826 1.76414 20.4585 1.76704C10.3326 1.77053 2.07959 10.0294 2.08714 20.1512H2.08656Z"
                  fill="#FF7927"
                />
                <path
                  d="M20.4521 30.695C19.6358 29.7313 18.8161 28.8001 18.0364 27.8364C16.336 25.7337 14.6994 23.5793 13.5145 21.134C13.1018 20.2829 12.7389 19.3755 12.552 18.4536C12.2193 16.8124 12.628 15.2455 13.4135 13.7884C15.0141 10.8189 18.4062 9.13413 21.6584 9.66939C25.1504 10.2441 27.8 12.8502 28.4003 16.2673C28.6662 17.7802 28.3579 19.1857 27.7292 20.5529C26.8665 22.4286 25.7548 24.1546 24.4985 25.7807C23.284 27.3528 21.9899 28.864 20.7301 30.4001C20.6541 30.4924 20.5658 30.5748 20.4526 30.695H20.4521ZM20.459 20.5285C22.1478 20.5337 23.5301 19.1665 23.5365 17.4853C23.5429 15.8029 22.1751 14.4223 20.4875 14.4072C18.8167 14.3927 17.4187 15.7767 17.4077 17.4568C17.3966 19.1329 18.7783 20.5239 20.459 20.5285Z"
                  fill="#FF7927"
                />
              </svg>{" "}
              <span className="mt-1.5 ml-4 sm:ml-0 col-span-11 md:col-span-10 2xl:col-span-11">
                2331 130TH AVE NE STE 110A, BELLEVUE, WA, 98005-1760
              </span>
            </Link>
          </nav>
        </div>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          {/* <a href="/">
            <span className=" sr-only">Instagram</span>
            <img className="px-6" src={Instagram} alt="Instagram" />
          </a>
          <a href="/">
            <span className=" sr-only">Twitter</span>
            <img className="px-6" src={Twitter} alt="Twitter" />
          </a>
          <a href="/">
            <span className=" sr-only">Facebook</span>
            <img className="px-6" src={Facebook} alt="Facebook" />
          </a> */}

          <div className="flex-grow border-t border-gray-400"></div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
