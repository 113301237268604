function OurValue() {
  return (
    <section className="pt-20 pb-10 bg-[#F5F5FC] ">
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <div className=" mx-10 lg:mx-40 ">
        <p className="my-4 md:my-5 text-[12px] md:text-base flex justify-start md:justify-end uppercase font-normal text-[#000000]">
          Our Value
        </p>
        <div className=" grid grid-cols-1 md:gap-8 md:grid-cols-7">
          <div className="pb-4 text-white md:col-span-3 ">
            {" "}
            <img
              src="https://i.ibb.co/KrP55cR/OURVALUE.png"
              alt="OURVALUE"
              className="w-full h-full rounded-3xl col-span-2"
            />
          </div>
          <div className=" content-center text-white  md:col-span-4">
            <div className="text-[24px] lg:text-[32px] xl:text-[40px] text-[#1E1E1E] font-bold">
              What do we do?
            </div>
            <div className="mt-4 lg:mt-8 text-[14px] lg:text-[18px] xl:text-[20px] text-[#1E1E1E] font-normal">
              <p className="">
                We empower individuals and businesses through our world-class
                accelerator services. What makes us stand out is our exceptional
                customer service, top-tier products, and seamless logistics. 
              </p>
              <p className="mt-4">
                We are a one-stop solution for end-to-end e-commerce services,
                leveraging an accelerated model that provides comprehensive
                support to brands, encompassing digital marketing, supply chain
                management, and listings optimization.
              </p>
              <p className="mt-4">
                We don't just pave the way to success; we redefine the journey
                itself on platforms such as Amazon and Zomonics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurValue;
