import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function HomeHeroSection() {
  const navigate = useNavigate();
  return (
    <div className=" lg:px-8">
      <div
        className="overflow-hidden z-10 absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-20rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#FFF] via-[#FF792733] to-[#FFF] sm:left-[calc(50%+40rem)] sm:w-[120rem]"
          style={{
            clipPath: "circle(30%)",
          }}
        ></div>
      </div>
      <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-[26px] font-semibold tracking-tight text-[#000000] leading-none md:text-[42px]  lg:text-[64px]">
            Your Business, Amplified - The Trusted Accelerator for{" "}
            <span className="text-[#FF7927] font-extrabold">
              Amazon Marketplace Growth
            </span>
          </h1>
          <p className="mt-7 px-6 text-center text-[16px] lg:text-xl text-[#000000]">
            Stockyfy is the ultimate Amazon acceleration partner, providing
            data-driven strategies and expert support to fuel your brand's
            growth in the US Marketplace.
          </p>
          <div className=" mt-10 flex items-center justify-center gap-x-6">
            <HashLink className="z-10" smooth to="/contact">
              <div className=" transition-all duration-500 hover:text-[#000000] text-[#ffffff] hover:bg-transparent  bg-[#FF7927] border-[#FF7927] border hover:border-[#000000] font-manrope text-sm md:text-base font-medium text-[#ffffff] px-6 py-2 lg:py-5 lg:px-16 rounded-full">
                Schedule a Meeting
              </div>
            </HashLink>
            {/* <button
              onClick={() => {
                navigate("/contact");
              }}
              className="z-10 transition-all duration-500 bg-transparent hover:bg-[#FF7927] hover:border-[#FF7927] border border-[#000000] font-manrope text-sm md:text-base font-medium text-[#000000] hover:text-[#ffffff] px-6 py-2 lg:py-5 lg:px-9 rounded-full inline-flex items-center"
            >
              <span className="mr-2">Connect With Us</span>
              <span aria-hidden="true">&rarr;</span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHeroSection;
