function ContactUsHeroSection() {
  return (
    <div className=" lg:px-8">
      <div className="mx-auto max-w-4xl py-8 sm:py-18 lg:pb-28 lg:pt-10">
        <div className="text-center">
          <h1 className="text-[24] md:text-[30px] font-extrabold tracking-tight text-[#fff]  sm:text-[40px]">
            Contact <span className="text-[#FF7927] font-extrabold">Us</span>
          </h1>
          <p className="mt-2 px-6 text-center text-[14px] md:text-xl text-[#ffffff]">
            Stockyfy is the ultimate Amazon acceleration partner, providing
            data-driven strategies and expert support to fuel your brand's
            growth in the US Marketplace.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUsHeroSection;
