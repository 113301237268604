import { useLayoutEffect } from "react";
import ExponentialGrowth from "../banners/exponentialGrowth";
import GrowFaster from "../banners/growFaster";
import OurService from "../banners/ourServices";
import WhyChooseUs from "../banners/whyChooseUs";
import WorkWithUsSection from "../banners/wortkWithUs";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import HomeHeroSection from "../Hero/HomeHeroSection";

function HomePage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <div className="relative  isolate bg-cover  bg-[url('https://i.ibb.co/G2dkCzX/Home-Hero-Section.png')] bg-no-repeat">
        <HomeHeroSection />
      </div>
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <GrowFaster />

      <OurService />
      <WhyChooseUs />
      <ECommerceBrands />
      <WorkWithUsSection />
      <div className="items-center mt-8 md:mt-24">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <ExponentialGrowth />
      <Footer />
    </div>
  );
}

export default HomePage;
