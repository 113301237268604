function WeOffer(props) {
  return (
    <section className="mt-16 pb-10 pt-20 bg-[#F5F5FC] ">
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <div className=" mx-10 lg:mx-40 ">
        <p className="my-4 md:my-5 text-[12px] md:text-base uppercase font-normal text-[#000000] flex justify-start md:justify-end">
          We Offer
        </p>
        <div className="grid grid-cols-1 md:gap-8 md:grid-cols-7">
          <div className="pb-4 text-white md:col-span-3 ">
            {" "}
            <img
              src="https://i.ibb.co/MMDYnsT/WEOFFER.png"
              alt="We Offer"
              className="w-full h-full rounded-3xl col-span-2"
            />
          </div>
          <div className=" content-center text-white md:col-span-4">
            <div className="text-[24px] lg:text-[32px] xl:text-[40px] text-[#1E1E1E] font-bold">
              What We Offer to Our Stakeholders
            </div>
            <div className="mt-4 lg:mt-8 text-[14px] lg:text-[18px] xl:text-[20px] text-[#1E1E1E] font-normal">
              <p className="">
                Stockyfy distinguishes itself by offering robust support,
                transparent communication, and unparalleled convenience to our
                stakeholders. With an annual revenue of $30 million, we pride
                ourselves on problem-solving abilities, cultivating strong
                relationships, and prioritizing the well-being of our partners.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WeOffer;
