import React, { useEffect, useState } from "react";
function ReachUsNow() {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    contactNumber: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsError(false);
      setIsSuccess(false);
    }, 5000);
  }, [isError, isSuccess]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  console.log("🚀 ~ ReachUsNow ~ formData:", formData);
  const handleSubmit = async (e) => {
    setIsError(false);
    setIsSuccess(false);
    setIsSubmitted(true);
    e.preventDefault();
    try {
      const response = await fetch(
        "https://stockyfy-backend.vercel.app/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName: formData.fullName,
            companyName: formData.companyName,
            email: formData.email,
            contactNumber: formData.contactNumber,
            message: formData.message,
            subject: "Contact Form Inquiry",
          }),
        }
      );

      if (response.ok) {
        setIsSubmitted(false);
        setIsError(false);
        setIsSuccess(true);
        // alert("Email sent successfully");
      } else {
        setIsSubmitted(false);
        setIsError(true);
        setIsSuccess(false);
        // alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email");
    }
  };
  return (
    <section className="" id="contact">
      <div className="grid grid-cols-1">
        <div className="pl-10 py-16 sm:pl-20 lg:pl-28 lg:py-20 h-full w-full pr-10 bg-[#F5F5FC]">
          {" "}
          <h2 className="mb-6 lg:my-0  flex text-center justify-center text-[26px] md:text-[40px] text-[#111111] font-bold ">
            Reach us now
          </h2>
          <div className="hidden lg:block my-6 items-center">
            <hr className="h-0.5 bg-[#646464]" />
          </div>
          <div className="hidden lg:grid mb-6 lg:mb-0 grid-cols-1 lg:grid-cols-3">
            <div className="flex gap-x-6 p-4">
              <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                >
                  <path
                    d="M40.3201 21.2807C40.2441 21.8804 40.1791 22.4825 40.0891 23.0804C39.3733 27.838 37.2055 31.8205 33.6311 35.0251C30.7707 37.59 27.4239 39.2039 23.6271 39.7798C17.9848 40.6361 12.8348 39.3682 8.31465 35.8617C4.03487 32.5427 1.50427 28.1486 0.787301 22.7785C-0.587431 12.4791 6.22992 2.91285 16.4318 0.766576C17.2754 0.588929 18.1433 0.529133 18.9996 0.411863C19.1262 0.394446 19.2504 0.363677 19.3752 0.339294C20.0991 0.339294 20.8231 0.339294 21.547 0.339294C21.6591 0.363097 21.7705 0.400832 21.8837 0.40896C25.8837 0.694588 29.4808 2.05771 32.6279 4.53432C36.798 7.81498 39.2978 12.0924 40.0937 17.3493C40.182 17.9339 40.2452 18.5226 40.3195 19.1089V21.2807H40.3201ZM2.08714 20.2067C2.09469 30.3146 10.3286 38.5491 20.4452 38.5665C30.5612 38.5833 38.8304 30.325 38.8357 20.2009C38.8403 10.0774 30.5832 1.81969 20.4591 1.82259C10.3332 1.82607 2.08018 10.0849 2.08772 20.2067H2.08714Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M17.1892 18.5546C18.4739 20.5406 20.1076 22.172 22.1209 23.4788C22.4379 23.0283 22.752 22.5853 23.0632 22.14C23.6269 21.3342 24.0147 21.2118 24.936 21.538C26.0077 21.9177 26.9818 22.4779 27.9003 23.1397C28.3061 23.4318 28.482 23.8422 28.3989 24.3328C28.2741 25.066 28.1394 25.7986 27.9763 26.5243C27.7307 27.6163 26.7711 28.241 25.6594 28.1261C19.5758 27.4944 14.132 22.3531 12.8078 16.5958C12.6784 16.0333 12.586 15.4585 12.5257 14.8838C12.4171 13.8522 13.0545 12.9384 14.0577 12.6992C14.8113 12.5198 15.5759 12.3846 16.3399 12.2557C16.8176 12.175 17.2211 12.3526 17.5068 12.7468C18.2202 13.732 18.8107 14.7834 19.1944 15.9433C19.4075 16.5865 19.2548 17.0649 18.7021 17.462C18.215 17.8121 17.7221 18.154 17.2322 18.5C17.224 18.5058 17.2194 18.5163 17.1892 18.5552V18.5546Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div>
                <p className="mt-3 text-base font-medium leading-6 text-[#1E1E1E]">
                  (425) 654-0681
                </p>
              </div>
            </div>
            <div className="flex gap-x-6 p-4">
              <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    d="M40.3195 21.0353C40.2435 21.635 40.1785 22.237 40.0885 22.835C39.3727 27.5925 37.2049 31.5751 33.6305 34.7797C30.7701 37.3445 27.4233 38.9585 23.6265 39.5344C17.9842 40.3907 12.8342 39.1227 8.31407 35.6163C4.03429 32.2967 1.50369 27.9025 0.787301 22.5325C-0.58743 12.233 6.22991 2.66682 16.4318 0.520544C17.2754 0.342897 18.1433 0.2831 18.9996 0.16583C19.1256 0.148414 19.2498 0.117645 19.3752 0.0932617C20.0991 0.0932617 20.8231 0.0932617 21.547 0.0932617C21.6591 0.117064 21.7705 0.1548 21.8837 0.162928C25.8837 0.448556 29.4808 1.81168 32.6279 4.28828C36.798 7.56895 39.2978 11.8464 40.0937 17.1032C40.1819 17.6879 40.2452 18.2765 40.3195 18.8629V21.0347V21.0353ZM2.08656 19.9618C2.09411 30.0697 10.328 38.3042 20.4446 38.3216C30.5606 38.3384 38.8299 30.0802 38.8351 19.956C38.8397 9.83249 30.5826 1.57481 20.4585 1.57772C10.3326 1.5812 2.0796 9.84004 2.08714 19.9618H2.08656Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M29.449 15.3134V15.7151C29.449 18.5383 29.4496 21.3615 29.449 24.1853C29.449 25.4909 28.6566 26.2857 27.3538 26.2863C22.7553 26.2874 18.1568 26.2874 13.5578 26.2863C12.2788 26.2863 11.4823 25.4834 11.4817 24.1992C11.4811 21.3545 11.4771 18.5099 11.4875 15.6652C11.4887 15.343 11.57 15.0214 11.624 14.6289C11.9642 14.8634 12.2254 15.0405 12.4838 15.2211C14.9284 16.9284 17.3789 18.6283 19.8114 20.3525C20.2613 20.6718 20.6224 20.6828 21.0915 20.3937C23.7742 18.7397 26.4726 17.1102 29.1657 15.473C29.2377 15.4295 29.3126 15.39 29.449 15.3134Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M28.7706 14.0727C25.9921 15.7627 23.2467 17.4329 20.4943 19.1067C17.9353 17.3192 15.3873 15.5392 12.8398 13.7598C12.8404 13.7331 12.841 13.7064 12.8416 13.6797C13.0314 13.6536 13.2207 13.6042 13.4105 13.6042C18.1158 13.6002 22.8212 13.5996 27.5265 13.6031C27.9758 13.6031 28.3863 13.7384 28.7706 14.0733V14.0727Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div>
                <p className="mt-3 text-base font-medium leading-6 text-[#1E1E1E]">
                  partnerships@stockyfy.com
                </p>
              </div>
            </div>
            <div className="flex gap-x-6 p-4">
              <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    d="M40.3195 21.2246C40.2435 21.8243 40.1785 22.4263 40.0885 23.0243C39.3727 27.7819 37.2049 31.7644 33.6305 34.969C30.7702 37.5339 27.4233 39.1478 23.6265 39.7237C17.9842 40.58 12.8342 39.3121 8.31407 35.8056C4.03429 32.486 1.50369 28.0919 0.787301 22.7218C-0.58743 12.4224 6.22991 2.85615 16.4318 0.709873C17.2754 0.532226 18.1433 0.472431 18.9996 0.355161C19.1256 0.337745 19.2498 0.306976 19.3752 0.282593C20.0991 0.282593 20.8231 0.282593 21.547 0.282593C21.6591 0.306395 21.7705 0.344128 21.8837 0.352255C25.8837 0.637884 29.4808 2.001 32.6279 4.47761C36.798 7.75827 39.2978 12.0357 40.0937 17.2926C40.1819 17.8772 40.2452 18.4659 40.3195 19.0522V21.224V21.2246ZM2.08656 20.1512C2.09411 30.2591 10.328 38.4935 20.4446 38.5109C30.5606 38.5278 38.8299 30.2695 38.8351 20.1454C38.8397 10.0218 30.5826 1.76414 20.4585 1.76704C10.3326 1.77053 2.07959 10.0294 2.08714 20.1512H2.08656Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M20.4521 30.695C19.6358 29.7313 18.8161 28.8001 18.0364 27.8364C16.336 25.7337 14.6994 23.5793 13.5145 21.134C13.1018 20.2829 12.7389 19.3755 12.552 18.4536C12.2193 16.8124 12.628 15.2455 13.4135 13.7884C15.0141 10.8189 18.4062 9.13413 21.6584 9.66939C25.1504 10.2441 27.8 12.8502 28.4003 16.2673C28.6662 17.7802 28.3579 19.1857 27.7292 20.5529C26.8665 22.4286 25.7548 24.1546 24.4985 25.7807C23.284 27.3528 21.9899 28.864 20.7301 30.4001C20.6541 30.4924 20.5658 30.5748 20.4526 30.695H20.4521ZM20.459 20.5285C22.1478 20.5337 23.5301 19.1665 23.5365 17.4853C23.5429 15.8029 22.1751 14.4223 20.4875 14.4072C18.8167 14.3927 17.4187 15.7767 17.4077 17.4568C17.3966 19.1329 18.7783 20.5239 20.459 20.5285Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div>
                <p className="mt-3 text-base font-medium leading-6 text-[#1E1E1E]">
                  2331 130TH AVE NE STE 110A, BELLEVUE, WA, 98005-1760
                </p>
              </div>
            </div>
          </div>
          <ul className="block lg:hidden mb-6 md:mb-0">
            <li className="flex ">
              <div className=" flex h-10 w-10 content-center items-center justify-center rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                >
                  <path
                    d="M40.3201 21.2807C40.2441 21.8804 40.1791 22.4825 40.0891 23.0804C39.3733 27.838 37.2055 31.8205 33.6311 35.0251C30.7707 37.59 27.4239 39.2039 23.6271 39.7798C17.9848 40.6361 12.8348 39.3682 8.31465 35.8617C4.03487 32.5427 1.50427 28.1486 0.787301 22.7785C-0.587431 12.4791 6.22992 2.91285 16.4318 0.766576C17.2754 0.588929 18.1433 0.529133 18.9996 0.411863C19.1262 0.394446 19.2504 0.363677 19.3752 0.339294C20.0991 0.339294 20.8231 0.339294 21.547 0.339294C21.6591 0.363097 21.7705 0.400832 21.8837 0.40896C25.8837 0.694588 29.4808 2.05771 32.6279 4.53432C36.798 7.81498 39.2978 12.0924 40.0937 17.3493C40.182 17.9339 40.2452 18.5226 40.3195 19.1089V21.2807H40.3201ZM2.08714 20.2067C2.09469 30.3146 10.3286 38.5491 20.4452 38.5665C30.5612 38.5833 38.8304 30.325 38.8357 20.2009C38.8403 10.0774 30.5832 1.81969 20.4591 1.82259C10.3332 1.82607 2.08018 10.0849 2.08772 20.2067H2.08714Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M17.1892 18.5546C18.4739 20.5406 20.1076 22.172 22.1209 23.4788C22.4379 23.0283 22.752 22.5853 23.0632 22.14C23.6269 21.3342 24.0147 21.2118 24.936 21.538C26.0077 21.9177 26.9818 22.4779 27.9003 23.1397C28.3061 23.4318 28.482 23.8422 28.3989 24.3328C28.2741 25.066 28.1394 25.7986 27.9763 26.5243C27.7307 27.6163 26.7711 28.241 25.6594 28.1261C19.5758 27.4944 14.132 22.3531 12.8078 16.5958C12.6784 16.0333 12.586 15.4585 12.5257 14.8838C12.4171 13.8522 13.0545 12.9384 14.0577 12.6992C14.8113 12.5198 15.5759 12.3846 16.3399 12.2557C16.8176 12.175 17.2211 12.3526 17.5068 12.7468C18.2202 13.732 18.8107 14.7834 19.1944 15.9433C19.4075 16.5865 19.2548 17.0649 18.7021 17.462C18.215 17.8121 17.7221 18.154 17.2322 18.5C17.224 18.5058 17.2194 18.5163 17.1892 18.5552V18.5546Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div className="ml-5 content-center">
                <p className=" text-base font-medium leading-6 text-[#1E1E1E]">
                  (425) 654-0681
                </p>
              </div>
            </li>
            <li>
              <div className="my-5 items-center">
                <hr className="h-0.5 bg-[#646464]" />
              </div>
            </li>
            <li className="flex mt-12 ">
              <div className=" flex h-10 w-10 content-center items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    d="M40.3195 21.0353C40.2435 21.635 40.1785 22.237 40.0885 22.835C39.3727 27.5925 37.2049 31.5751 33.6305 34.7797C30.7701 37.3445 27.4233 38.9585 23.6265 39.5344C17.9842 40.3907 12.8342 39.1227 8.31407 35.6163C4.03429 32.2967 1.50369 27.9025 0.787301 22.5325C-0.58743 12.233 6.22991 2.66682 16.4318 0.520544C17.2754 0.342897 18.1433 0.2831 18.9996 0.16583C19.1256 0.148414 19.2498 0.117645 19.3752 0.0932617C20.0991 0.0932617 20.8231 0.0932617 21.547 0.0932617C21.6591 0.117064 21.7705 0.1548 21.8837 0.162928C25.8837 0.448556 29.4808 1.81168 32.6279 4.28828C36.798 7.56895 39.2978 11.8464 40.0937 17.1032C40.1819 17.6879 40.2452 18.2765 40.3195 18.8629V21.0347V21.0353ZM2.08656 19.9618C2.09411 30.0697 10.328 38.3042 20.4446 38.3216C30.5606 38.3384 38.8299 30.0802 38.8351 19.956C38.8397 9.83249 30.5826 1.57481 20.4585 1.57772C10.3326 1.5812 2.0796 9.84004 2.08714 19.9618H2.08656Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M29.449 15.3134V15.7151C29.449 18.5383 29.4496 21.3615 29.449 24.1853C29.449 25.4909 28.6566 26.2857 27.3538 26.2863C22.7553 26.2874 18.1568 26.2874 13.5578 26.2863C12.2788 26.2863 11.4823 25.4834 11.4817 24.1992C11.4811 21.3545 11.4771 18.5099 11.4875 15.6652C11.4887 15.343 11.57 15.0214 11.624 14.6289C11.9642 14.8634 12.2254 15.0405 12.4838 15.2211C14.9284 16.9284 17.3789 18.6283 19.8114 20.3525C20.2613 20.6718 20.6224 20.6828 21.0915 20.3937C23.7742 18.7397 26.4726 17.1102 29.1657 15.473C29.2377 15.4295 29.3126 15.39 29.449 15.3134Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M28.7706 14.0727C25.9921 15.7627 23.2467 17.4329 20.4943 19.1067C17.9353 17.3192 15.3873 15.5392 12.8398 13.7598C12.8404 13.7331 12.841 13.7064 12.8416 13.6797C13.0314 13.6536 13.2207 13.6042 13.4105 13.6042C18.1158 13.6002 22.8212 13.5996 27.5265 13.6031C27.9758 13.6031 28.3863 13.7384 28.7706 14.0733V14.0727Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div className="ml-5 content-center">
                <p className=" text-base font-medium leading-6 text-[#1E1E1E]">
                  partnerships@stockyfy.com
                </p>
              </div>
            </li>

            <li>
              <div className="my-5 items-center">
                <hr className="h-0.5 bg-[#646464]" />
              </div>
            </li>
            <li className="flex mt-12 ">
              <div className=" flex h-10 w-10 content-center items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                >
                  <path
                    d="M40.3195 21.2246C40.2435 21.8243 40.1785 22.4263 40.0885 23.0243C39.3727 27.7819 37.2049 31.7644 33.6305 34.969C30.7702 37.5339 27.4233 39.1478 23.6265 39.7237C17.9842 40.58 12.8342 39.3121 8.31407 35.8056C4.03429 32.486 1.50369 28.0919 0.787301 22.7218C-0.58743 12.4224 6.22991 2.85615 16.4318 0.709873C17.2754 0.532226 18.1433 0.472431 18.9996 0.355161C19.1256 0.337745 19.2498 0.306976 19.3752 0.282593C20.0991 0.282593 20.8231 0.282593 21.547 0.282593C21.6591 0.306395 21.7705 0.344128 21.8837 0.352255C25.8837 0.637884 29.4808 2.001 32.6279 4.47761C36.798 7.75827 39.2978 12.0357 40.0937 17.2926C40.1819 17.8772 40.2452 18.4659 40.3195 19.0522V21.224V21.2246ZM2.08656 20.1512C2.09411 30.2591 10.328 38.4935 20.4446 38.5109C30.5606 38.5278 38.8299 30.2695 38.8351 20.1454C38.8397 10.0218 30.5826 1.76414 20.4585 1.76704C10.3326 1.77053 2.07959 10.0294 2.08714 20.1512H2.08656Z"
                    fill="#FF7927"
                  />
                  <path
                    d="M20.4521 30.695C19.6358 29.7313 18.8161 28.8001 18.0364 27.8364C16.336 25.7337 14.6994 23.5793 13.5145 21.134C13.1018 20.2829 12.7389 19.3755 12.552 18.4536C12.2193 16.8124 12.628 15.2455 13.4135 13.7884C15.0141 10.8189 18.4062 9.13413 21.6584 9.66939C25.1504 10.2441 27.8 12.8502 28.4003 16.2673C28.6662 17.7802 28.3579 19.1857 27.7292 20.5529C26.8665 22.4286 25.7548 24.1546 24.4985 25.7807C23.284 27.3528 21.9899 28.864 20.7301 30.4001C20.6541 30.4924 20.5658 30.5748 20.4526 30.695H20.4521ZM20.459 20.5285C22.1478 20.5337 23.5301 19.1665 23.5365 17.4853C23.5429 15.8029 22.1751 14.4223 20.4875 14.4072C18.8167 14.3927 17.4187 15.7767 17.4077 17.4568C17.3966 19.1329 18.7783 20.5239 20.459 20.5285Z"
                    fill="#FF7927"
                  />
                </svg>
              </div>
              <div className="ml-5 content-center">
                <p className=" text-base font-medium leading-6 text-[#1E1E1E]">
                  2331 130TH AVE NE STE 110A, BELLEVUE, WA, 98005-1760
                </p>
              </div>
            </li>

            <li>
              <div className="my-5 items-center">
                <hr className="h-0.5 bg-[#646464]" />
              </div>
            </li>
          </ul>
        </div>
        <div className="col-span-2 px-4 py-6 2xl:py-16 sm:px-6 lg:px-8  h-full pr-6 ">
          <h2 className="mb-6 lg:my-0  flex text-center justify-center text-[26px] md:text-[40px] text-[#111111] font-bold ">
            Get in touch
          </h2>
          <div className=" my-6 items-center">
            <hr className="h-0.5 bg-[#646464]" />
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full lg:px-[200px] xl:px-[300px] 2xl:px-[400px]"
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  required
                  value={formData.fullName}
                  name="fullName"
                  onChange={handleChange}
                  className=" block w-full  text-[#4C4B4B border  border-[#0000008f] rounded-[5px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-none focus:bg-gray-200"
                  id="grid-first-name"
                  placeholder="Full Name"
                  type="text"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <input
                  required
                  value={formData.companyName}
                  name="companyName"
                  onChange={handleChange}
                  className=" block w-full  text-[#4C4B4B border  border-[#0000008f] rounded-[5px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-none focus:bg-gray-200"
                  id="grid-first-name"
                  placeholder="Company Name"
                  type="text"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                  required
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                  className=" block w-full  text-[#4C4B4B border  border-[#0000008f] rounded-[5px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-none focus:bg-gray-200"
                  id="grid-first-name"
                  placeholder="Email"
                  type="email"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <input
                  required
                  value={formData.contactNumber}
                  name="contactNumber"
                  onChange={handleChange}
                  className=" block w-full  text-[#4C4B4B border  border-[#0000008f] rounded-[5px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-none focus:bg-gray-200"
                  id="grid-first-name"
                  placeholder="Phone Number"
                  type="text"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <textarea
                  value={formData.message}
                  name="message"
                  onChange={handleChange}
                  className=" block w-full text-[#4C4B4B border  border-[#0000008f] rounded-[5px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-none focus:bg-gray-200"
                  id="grid-textarea"
                  placeholder="How can we help you!"
                  type="text"
                />
              </div>
            </div>
            {isError && (
              <div
                class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <span class="font-medium">We apologize </span>for the
                inconvenience, but we are currently unable to send emails.
                Please try again later.
              </div>
            )}
            {isSuccess && (
              <div
                class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                role="alert"
              >
                <span class="font-medium">Thank you </span>
                for reaching out to us via email. One of our agents will be in
                touch with you shortly.
              </div>
            )}
            <div className="w-full flex flex-wrap mb-6 round-full text-center">
              {!isSubmitted ? (
                <button
                  type="submit"
                  className="w-full bg-[#FF7927] text-white px-6 py-3 text-xl md:text-2xl rounded-full sm:mb-0"
                >
                  <span className="mr-2">Submit</span>
                  <span aria-hidden="true">&rarr;</span>
                </button>
              ) : (
                <button
                  disabled
                  type="button"
                  class="w-full bg-[#FF7927AF] text-white px-6 py-3 text-xl md:text-2xl rounded-full sm:mb-0"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-6 h-6 me-3 text-gray-200 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  Submitting...
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ReachUsNow;
