import { useEffect, useState } from "react";
import StockyfyLogoBlack from "../../assets/logos/StockyfyLogoBlack.svg";
import StockyfyLogoWhite from "../../assets/logos/StockyfyLogoWhite.svg";
import ECommerceMarketplace from "../../assets/images/ECommerceMarketplace.png";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [isExpandedService, setIsExpandedService] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <header
      className={`bg-${
        !props.background || isOpenServices || isScrolled
          ? "white"
          : "transparent"
      } z-40 py-6 md:sticky md:top-0`}
    >
      <nav
        className=" flex xl:mx-40 items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex md:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className=" sr-only">Stockyfy</span>
            <img
              className="h-8 w-auto"
              src={
                !props.background || isOpenServices || isScrolled
                  ? StockyfyLogoBlack
                  : StockyfyLogoWhite
              }
              alt="Stockify"
            />
          </Link>
        </div>
        {!isOpen ? (
          <div className="flex md:hidden">
            <button
              type="button"
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[${
                !props.background || isOpenServices || isScrolled
                  ? "#000000"
                  : "#ffffff"
              }]`}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
        ) : null}
        <div className="hidden md:flex md:gap-x-10 lg:gap-x-32">
          <button
            onClick={() => {
              setIsOpenServices(!isOpenServices);
              setIsExpandedService(!isExpandedService);
            }}
            className={`flex font-manrope text-base font-normal leading-6 text-[${
              !props.background || isOpenServices || isScrolled
                ? "#000000"
                : "#ffffff"
            }] uppercase`}
          >
            Services
            <svg
              className={`mt-1 ml-1.5  ${
                isExpandedService ? "rotate-180" : "rotate-0"
              }`}
              fill={
                !props.background || isOpenServices || isScrolled
                  ? "#000000"
                  : "#ffffff"
              }
              height="15px"
              width="15px"
              id="Layer_1"
              // xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
            >
              <path
                id="XMLID_225_"
                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
              />
            </svg>
          </button>
          <Link
            to="/about"
            className={`font-manrope text-base font-normal leading-6 text-[${
              !props.background || isOpenServices || isScrolled
                ? "#000000"
                : "#ffffff"
            }] uppercase`}
          >
            About
          </Link>
          {/* <Link
            to="/"
            className={`font-manrope text-base font-normal leading-6 text-[${
              !props.background || isOpenServices || isScrolled
                ? "#000000"
                : "#ffffff"
            }] uppercase`}
          >
            Resources
          </Link> */}
        </div>
        <div className="hidden md:flex md:flex-1 md:justify-end">
          <button
            onClick={() => {
              navigate("/contact");
            }}
            className={`transition-all duration-500 bg-transparent hover:bg-[#FF7927] hover:border-[#FF7927] border border-[${
              !props.background || isOpenServices || isScrolled
                ? "#000000"
                : "#ffffff"
            }] font-manrope text-base font-normal leading-6 text-[${
              !props.background || isOpenServices || isScrolled
                ? "#000000"
                : "#ffffff"
            }] hover:text-[#ffffff] uppercase py-2.5 px-7 rounded-full inline-flex items-center`}
          >
            <span className="mr-2">Contact Us</span>
            <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </nav>
      {isOpenServices ? (
        <div className="hidden md:block relative z-20">
          <div className="absolute  w-full left-1/2 transform -translate-x-1/2  mt-1 bg-[#F5F5FC] border-gray-200 shadow-sm ">
            <div className="grid px-2 xl:px-4 gap-x-1 xl:gap-x-8 justify-items-censter text-sm text-gray-500 md:grid-cols-4 md:px-14">
              <ul
                className="space-y-4 sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <div
                  onClick={() => navigate("/e-commerce-marketplace-management")}
                  className="h-full cursor-pointer  bg-white border border-gray-200 rounded-t-lg shadow"
                >
                  <img
                    className="w-full md:h-[150px]  lg:h-[200px] xl:h-[250px] rounded-t-lg"
                    src={ECommerceMarketplace}
                    alt=""
                  />
                  <div className="p-2 xl:p-5">
                    <p className="mb-2 text-sm lg:text-base font-bold  text-[#000000]">
                      E-Commerce Marketplace Management
                    </p>
                    <p className="text-xs lg:text-base text-[#808080]">
                      Optimize insights and reporting, protect brands...
                    </p>
                  </div>
                </div>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <div
                  onClick={() => navigate("/end-to-end-logistics-management")}
                  className="h-full cursor-pointer bg-white border border-gray-200 rounded-t-lg shadow"
                >
                  <img
                    className="w-full md:h-[150px]  lg:h-[200px] xl:h-[250px] rounded-t-lg"
                    src={"https://i.ibb.co/16Ln3P9/End-To-End-Logistics.png"}
                    alt="End-to End Logistics Management"
                  />
                  <div className="p-2 xl:p-5">
                    <p className="mb-2 text-sm lg:text-base font-bold  text-[#000000]">
                      End-to End Logistics Management
                    </p>
                    <p className="text-xs lg:text-base text-[#808080]">
                      Streamline logistics operations, optimizing...
                    </p>
                  </div>
                </div>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <div
                  onClick={() => {
                    navigate("/marketing-campaigns");
                  }}
                  className="h-full cursor-pointer bg-white border border-gray-200 rounded-t-lg shadow"
                >
                  <img
                    className="w-full md:h-[150px]  lg:h-[200px] xl:h-[250px] rounded-t-lg"
                    src={"https://i.ibb.co/z6yRC5N/Marketing-Campaigns.png"}
                    alt="Marketing Campaigns"
                  />
                  <div className="p-2 xl:p-5">
                    <p className="mb-2 text-sm lg:text-base font-bold  text-[#000000]">
                      Marketing Campaigns
                    </p>
                    <p className="text-xs lg:text-base text-[#808080]">
                      Maximize your brand's visibility with expertly...
                    </p>
                  </div>
                </div>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <div
                  onClick={() => {
                    navigate("/on-demand-service");
                  }}
                  className="h-full cursor-pointer bg-white border border-gray-200 rounded-t-lg shadow"
                >
                  <img
                    className="w-full md:h-[150px]  lg:h-[200px] xl:h-[250px] rounded-t-lg"
                    src={"https://i.ibb.co/Ss6tPFK/On-Demand-Service.png"}
                    alt="onDemandServices"
                  />
                  <div className="p-2 xl:p-5">
                    <p className="mb-2 text-sm lg:text-base font-bold  text-[#000000]">
                      On-Demand Services
                    </p>
                    <p className="text-xs lg:text-base text-[#808080]">
                      Ensure accurate stock records with our inventory...
                    </p>
                  </div>
                </div>
              </ul>
            </div>
            <div className="grid px-2 xl:px-4 gap-x-1 xl:gap-x-8 gap-x-8 justify-items-censter  py-5 text-sm text-gray-500 md:grid-cols-4 md:px-14">
              <ul
                className="space-y-4 sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <li className="px-2 md:px-4">
                  <Link
                    to="/insight-and-reporting"
                    className="text-xs lg:text-sm text-[#000] hover:underline hover:text-blue-600"
                  >
                    Insights and reporting
                  </Link>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <Link
                    to="/brands-protection"
                    className="text-xs lg:text-sm text-[#000] hover:underline hover:text-blue-600"
                  >
                    Brands Protection
                  </Link>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <Link
                    to="/listing-optimization"
                    className="text-xs lg:text-sm text-[#000] hover:underline hover:text-blue-600"
                  >
                    Listing Optimization
                  </Link>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <Link
                    to="/seller-central-management"
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Seller Central Management
                  </Link>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/end-to-end-logistics-management#shipping-services"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Shipping Services
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/end-to-end-logistics-management#warehousing"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Warehousing
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/end-to-end-logistics-management#inventory-management"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Inventory Management
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/end-to-end-logistics-management#packaging-labelling"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Packaging and Labelling
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/marketing-campaigns#ppc-campaigns"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    PPC Camapaigns
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/marketing-campaigns#meta-campaigns"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Meta Campaigns
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/marketing-campaigns#marketing-strategy"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Marketing Strategy
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
              </ul>
              <ul
                className="space-y-4  sm:mb-4 md:mb-0"
                aria-labelledby="mega-menu-full-cta-button"
              >
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/on-demand-service#inventory-reconciliation"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Inventory Reconciliation
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
                <li className="px-2 md:px-4">
                  <HashLink
                    smooth
                    to={"/on-demand-service#account-reinstatement"}
                    className="text-xs lg:text-sm text-[#000]  hover:underline hover:text-blue-600"
                  >
                    Account Reinstatment
                  </HashLink>
                  <div className="my-2 items-center">
                    <hr className="h-0.5 bg-[#808080]" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
      {isOpen ? (
        <div className="md:hidden" role="dialog" aria-modal="true">
          {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
          <div className="fixed inset-0 z-10"></div>
          <div className="fixed inset-y-0 right-0 z-20 w-full overflow-y-auto bg-white px-6 py-6 sm: sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Stockyfy</span>
                <img
                  className="h-8 w-auto"
                  src={StockyfyLogoBlack}
                  alt="Stockyfy"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setIsOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <div
                    onClick={() => {
                      setIsExpandedService(!isExpandedService);
                    }}
                    className="-mx-3 flex justify-between cursor-pointer block px-3 py-2 text-base font-semibold leading-7 text-[#1E1E1E] hover:bg-gray-50 uppercase"
                  >
                    Services
                    <svg
                      className={`mt-1.5 ${
                        isExpandedService ? "rotate-180" : "rotate-0"
                      }`}
                      fill="#000000"
                      height="15px"
                      width="15px"
                      id="Layer_1"
                      // xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 330 330"
                    >
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  </div>
                  {isExpandedService && (
                    <div className="py-2 divide-y divide-gray-500/10">
                      <Link
                        to="/e-commerce-marketplace-management"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        E-Commerce Marketplace Management
                      </Link>
                      <Link
                        to="/end-to-end-logistics-management"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        End-to End Logistics Management
                      </Link>
                      <Link
                        to="/marketing-campaigns"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        Marketing Campaigns
                      </Link>
                      <Link
                        to="/on-demand-service"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        On-Demand Services
                      </Link>
                      {/* <Link
                        to="/service/ecommerce"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        Insights and reporting
                      </Link>
                      <Link
                        to="/service/ecommerce"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        Brands Protection
                      </Link>
                      <Link
                        to="/service/ecommerce"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        Listing Optimization
                      </Link>
                      <Link
                        to="/service/ecommerce"
                        className="py-2 block ml-5 text-base font-normal leading-7 text-[#1E1E1E] hover:bg-gray-50"
                      >
                        Seller Central Management
                      </Link> */}
                    </div>
                  )}
                  <hr />
                  <Link
                    to="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#1E1E1E] hover:bg-gray-50 uppercase"
                  >
                    About
                  </Link>
                  {/* <hr /> */}
                  {/* <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#1E1E1E] hover:bg-gray-50 uppercase"
                  >
                    Resources
                  </Link> */}
                </div>
                <div className="py-6">
                  <button
                    onClick={() => {
                      navigate("/contact");
                    }}
                    className="-mx-3 bg-transparent hover:bg-gray-400 border border-[#000000] font-manrope text-base font-normal leading-6 text-[#000000] uppercase py-2 px-4 rounded-full inline-flex items-center"
                  >
                    <span className="mr-2">Contact Us</span>
                    <span aria-hidden="true">&rarr;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </header>
  );
}

export default Header;
