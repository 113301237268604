import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import OnDemandServiceImage from "../../assets/images/OnDemandService.png";
import ContentDetail from "../banners/contentDetail";
import HowItWorks from "../banners/howItWorks";
import { useLayoutEffect } from "react";

function OnDemandService() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <div className="bg-[#F5F5FC]">
        <section className="mx-10 lg:mx-40 py-10 md:py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="text-[26px]  xl:text-[36px] 2xl:text-[48px] text-[#1E1E1E] font-bold">
              ON-DEMAND SERVICES
            </div>
            <div className="flex justify-start md:text-left md:justify-end text-[16px] xl:text-xl text-[#1E1E1E] font-normal">
              <p className="max-w-full md:max-w-[490px]">
                Ensure accurate stock records with our inventory reconciliation
                services and swiftly resolve issues to get your Amazon account
                back on track with our expert account reinstatement.
              </p>
            </div>
          </div>
        </section>
      </div>
      <img
        className="object-fill h-4/5 w-full"
        alt="OnDemandServiceImage"
        src={OnDemandServiceImage}
      />
      <ContentDetail
        margin={true}
        title="Inventory Reconciliation"
        id="inventory-reconciliation"
        detail="Maintain consistence and ensure that your inventory records are always updated and accurate through our inventory reconciliation services. We assist you in identifying discrepencies, minimizing errors, and maintaining a crystal clear overview of your stock."
      />
      <ContentDetail
        margin={true}
        title="Account Reinstatement"
        id="account-reinstatement"
        detail="With our account reinstatement services, you can get your Amazon account back on track. Our Amazon professionals swiftly resolve problems by navigating the intricacies of Amazon's policies, allowing you to quickly resume your business operations with reduced downtime."
      />
      {/* <ContentDetail title="" detail="" /> */}
      <HowItWorks eCommerceBrands={false} />
      <ECommerceBrands />
      <WantToLearnMore />

      <Footer />
    </div>
  );
}

export default OnDemandService;
