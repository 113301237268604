import { useLayoutEffect } from "react";
import ContentDetailWithLeftImage from "../banners/contentDetailWithLeftImage";
import ContentDetailWithRightImage from "../banners/contentDetailWithRightImage";
import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import {
  brandProtectionDetails1,
  brandProtectionDetails2,
  brandProtectionDetails3,
  brandProtectionDetails4,
  brandProtectionDetails5,
} from "../../containers/staticsData/statics";

function BrandsProtection() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <div className="bg-[#F5F5FC]">
        <section className="pt-10 pb-10 md:pt-20 md:pb-40">
          <div className="flex justify-center text-center text-[24px] md:text-[56px] xl:text-[96px] text-[#1E1E1E] font-bold">
            Brands Protection
          </div>
          <ContentDetailWithRightImage details={brandProtectionDetails1} />
          <ContentDetailWithLeftImage details={brandProtectionDetails2} />
          <ContentDetailWithRightImage details={brandProtectionDetails3} />
          <ContentDetailWithLeftImage details={brandProtectionDetails4} />
          <ContentDetailWithRightImage details={brandProtectionDetails5} />
        </section>
        <ECommerceBrands />
        <WantToLearnMore />

        <Footer />
      </div>
    </div>
  );
}

export default BrandsProtection;
