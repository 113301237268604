import React from "react";
import OurJourneyScroll from "./ourJourneyScroll";

const OurJurney = () => {
  return (
    <div className="my-20 mx-6 lg:mx-40">
      <div className="mb-20 flex justify-center text-center items-center ">
        <div className="text-[26px] md:text-[40px] text-[#1E1E1E] max-w-[900px] font-bold">
          Here’s A Journey Of How We Accelerated Our Success Since Our
          Inception.
        </div>
      </div>
      <OurJourneyScroll />
    </div>
  );
};

export default OurJurney;
