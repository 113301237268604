// Brand Protection
export const brandProtectionDetails1 = {
  image: "https://i.ibb.co/qsLgY2L/PRICEMONITORING.png",
  mobileImage: "https://i.ibb.co/jkthgNT/PRICEMONITORING-2.png",
  title: "PRICE MONITORING",
  description:
    "Protect and monitor your pricing on Amazon effectively through our brand protection tools. Keep your brand integrity intact and optimize your buy box performance.",
  lists: [
    {
      id: "monitor-price-changes",
      title: "Monitor Price Changes",
      description:
        "Real-time monitoring keeps you informed about who is lowering product prices and where changes occur. Stay about unauthorized adjustments that might be affecting your brand’s reputation and sales.",
    },
    {
      id: "maintain-pricing-consistency",
      title: "Maintain Pricing Consistency",
      description:
        "Utilize tools to enforce pricing policies and communicate with sellers to protect your brand from price erosion and discrepancies. Our insights help you maintain consistent pricing to win the Buy Box, easily.",
    },
    {
      id: "optimize-buy-box-performance",
      title: "Optimize Buy Box Performance",
      description:
        "Optimizing your buy box performance, listing, and pricing. Learn top-notch strategies to win the buy box with analysis on factors influencing eligibility and performance. Do all this without compromising margins.",
    },
    {
      id: "protect-profit-margins",
      title: "Protect Profit Margins",
      description:
        "Make data-driven decisions to safeguard your brand’s profitability. Monitor and manage pricing effectively to keep profit margin intact, and gain insights into how price changes impact your bottom line.",
    },
  ],
};
export const brandProtectionDetails2 = {
  image: "https://i.ibb.co/bJgc59W/BUYBOXMONITORING.png",
  mobileImage: "BUYBOXMONITORING",
  title: "BUY BOX MONITORING",
  description:
    "Track unauthorized sellers, enforce pricing policies, and optimize sales with our platform's insights to enhance your buy box performance.",
  lists: [
    {
      id: "track-buy-box-performance",
      title: "Track Buy Box Performance",
      description:
        "Get a competitive edge with detailed historical data to understand trends and patterns. Through our tools, you can easily track your buy box performance and identity exactly where you need to make amendments.",
    },
    {
      id: "identify-unauthorized-sellers",
      title: "Identify Unauthorized Sellers",
      description:
        "Easily spot unauthorized sellers causing sales friction and take corrective measures to protect your brand. Detect MAP violators efficiently to ensure consistent pricing and brand integrity.",
    },
    {
      id: "optimize-sales-and-profitability",
      title: "Optimize Sales and Profitability",
      description:
        "Determine the effect of unauthorized sellers and MAP violators on sales to make informed decisions about pricing and distribution strategies, leading to optimal buy box performance.",
    },
    {
      id: "comprehensive-reporting",
      title: "Comprehensive Reporting",
      description:
        "Generate custom reports tailored to your needs, providing specific metrics for strategic decisions. Evaluate your Buy Box status with key performance metrics to stay on top of the chart.",
    },
  ],
};
export const brandProtectionDetails3 = {
  image: "https://i.ibb.co/Q7Bghqp/MAPCOMPLIANCE.png",
  mobileImage: "https://i.ibb.co/3hQmydK/MAPCOMPLIANCE-2.png",
  title: "MAP COMPLIANCE",
  description:
    "Be vigilant and get the insights that you need for MAP compliance. We protect your brand’s value and reputation to maintain fair competition.",
  lists: [
    {
      id: "customizable-reporting",
      title: "Customizable Reporting",
      description:
        "Generate custom reports for detailed insights into your business operations. These reports help you focus on specific time frames, products, or sellers so that you can stay informed.",
    },
    {
      id: "continuous-seller-monitoring",
      title: "Continuous Seller Monitoring",
      description:
        "Utilize real-time tracking tools to monitor sellers, ensuring comprehensive oversight of your product distribution. Maintain an up-to-date database of all sellers for swift identification of issues such as MAP violations.",
    },
    {
      id: "enforce-pricing-policies",
      title: "Enforce Pricing Policies",
      description:
        "Receive instant alerts for pricing policy violations, enabling immediate intervention, while automated reporting tracks non-compliance instances, facilitating clear communication and enforcement.",
    },
    {
      id: "strategic-compliance-management",
      title: "Strategic Compliance Management",
      description:
        "Use actionable insights to enforce MAP compliance by engaging sellers, addressing violations, and ensuring policy adherence. Implement proactive measures by educating distributors about your policy.",
    },
  ],
};
export const brandProtectionDetails4 = {
  image: "https://i.ibb.co/58Xh4w0/MONITORPRODUCTSUPPRESSION.png",
  mobileImage: "https://i.ibb.co/MMQM4wC/MONITORPRODUCTSUPPRESSION-2.png",
  title: "MONITOR PRODUCT SUPPRESSION",
  description:
    "Track Buy Box ownership, identify suppression issues, and take corrective actions to maintain your brand’s presence and performance on Amazon.",
  lists: [
    {
      title: "Track Buy Box Ownership",
      id: "track-buy-box-ownership",
      description:
        "Continuously monitor Buy Box ownership to swiftly identify any anomalies affecting your product’s visibility and gain detailed insights into performance metrics, understanding loss frequency, and identifying compliance patterns.",
    },
    {
      title: "Identify Suppressed Products",
      id: "identify-suppressed-products",
      description:
        "Swiftly identify products suppressed due to compliance issues with alerts for hidden or removed listings. You can also track and address specific compliance issues causing suppression for resolution.",
    },
    {
      title: "Resolve Compliance Issues",
      id: "resolve-compliance-issues",
      description:
        "Receive guidance to resolve compliance issues and reinstate suppressed products. Proactively manage compliance with insights to prevent future suppression, ensuring ongoing visibility and competitiveness.",
    },
    {
      title: "Enhance Product Visibility",
      id: "enhance-product-visibility",
      description:
        "Enhance compliance and improve product listings with our tools, ensuring visibility and avoiding suppression. Conduct regular audits using our monitoring capabilities to maintain compliance and accessibility.",
    },
    {
      title: "Comprehensive Reporting",
      id: "comprehensive-reporting",
      description:
        "Gain insights into product suppression and compliance issues with comprehensive reports, understanding their impact on sales and brand visibility. Identify trends, refine compliance strategies, and prevent recurring issues.",
    },
  ],
};
export const brandProtectionDetails5 = {
  image: "https://i.ibb.co/DGPy1hp/BRANDPOLICYCOMPLIANCE.png",
  mobileImage: "https://i.ibb.co/Cw763Lf/BRANDPOLICYCOMPLIANCE-2.png",
  title: "BRAND POLICY COMPLIANCE",
  description:
    "Monitor and enforce your guidelines, preserving your brand’s integrity and value on Amazon to protect your reputation.",
  lists: [
    {
      title: "Monitor Brand Policy Adherence",
      id: "monitor-brand-policy-compliance",
      description:
        "Strengthen brand integreity with round-the-clock real-time monitoring of listings across Amazon. Our tools detect fluctuation in product descriptions, images, and pricing to prevent unauthorized changes.",
    },
    {
      title: "Maintain Consistent Brand Representation",
      id: "maintain-consistent-brand-representation",
      description:
        "No need to worry about variation in Amazon listings anymore. Our tools are ideal for ensuring uniformity for product images, descriptions and elements. We also manage authorized sellers to protect your image.",
    },
    {
      title: "Protect Intellectual Property",
      id: "protect-intellectual-property",
      description:
        "Protect your intellectual property by monitoring the use of your trademarks and other assets on Amazon. Receive prompt alerts on potential infringements to maintain correct usage by authorized sellers.",
    },
    {
      title: "Comprehensive Compliance Reporting",
      id: "comprehensive-compliance-reporting",
      description:
        "Assess brand policy compliance and highlight alarming areas through generate detailed reports. You can also easily document policy violations and ensure clear records of compliance issues.",
    },
    {
      title: "Strategic Enforcement on Amazon",
      id: "strategic-enforcement-on-amazon",
      description:
        "Employ aggressive enforcement tactics to guarantee that dealers follow your brand requirements. Whether it’s corresponding with vendors or making changes to listings, take focused action based on our observations.",
    },
    {
      title: "Enhance Brand Value",
      id: "enhance-brand-value",
      description:
        "Uphold excellent standards in all of your Amazon listings to protect your brand's reputation and increase consumer loyalty. Get a competitive edge to project a distinctive, authoritative, and professional image.",
    },
  ],
};

// Insight and Reporting
export const insightAndReportingDetails1 = {
  image: "https://i.ibb.co/XWmF5RN/MARKETSHAREANALYSIS.jpg",
  mobileImage: "https://i.ibb.co/wS3j7mM/market-Share-Analysis.png",
  title: "MARKET SHARE ANALYSIS",
  description:
    "Gain a competitive edge with Stocky’s market share analysis. We empower brands with powerful insights and comparison tools to enable data-driven decisions to drive Amazon growth.",
  lists: [
    {
      title: "Market Share Visualization",
      id: "market-share-visualization",
      description:
        "Determine your brand’s market share with our advanced intuitive dashboards that display your brand’s market share with your category, allowing competitive analysis and easy tracking.",
    },
    {
      title: "Competitor Insights",
      id: "competitor-insights",
      description:
        "Gain invaluable competitor insights to refine your strategies and outperform the. Compare your growth to understand market trends and use data visualizations to capitalize on opportunities.",
    },
    {
      title: "Actionable Strategies",
      id: "actionable-strategies",
      description:
        "Through comprehensive reports, you can make decision on your pricings, listings, and promotional offers. This will help you stay ahead with real-time alerts on competitor strategies and market changes.",
    },
    {
      title: "Custom Reporting",
      id: "custom-reporting",
      description:
        "We will provide you with customized reports according to your specific needs. These tailored reports are generated through access to historical and present data, allowing you to plan for the long-term.",
    },
  ],
};
export const insightAndReportingDetails2 = {
  image: "https://i.ibb.co/gTjdLd7/Ai-Recomendation.jpg",
  mobileImage: "https://i.ibb.co/zHfCYJs/Ai-Recomendation.png",
  title: "AI RECOMMENDATIONS",
  description:
    "Identify significant growth opportunities with our advanced AI tools. These AI recommendations will help you prioritize impactful actions.",
  lists: [
    {
      title: "Growth Opportunities",
      id: "growth-opportunities",
      description:
        "Yield highest returns through targetted AI analysis. Through our AI recommendation tools, you can analyze your data through charts and gain insights to pinpoint tailored growth opportunities.",
    },
    {
      title: "Strategic Assessment",
      id: "strategic-assessment",
      description:
        "Assess your feasibility, market conditions, competitor strengths, and resource availability. Use our customized action plans to align your brand’s capabilities and market dynamics for effective implementation.",
    },
    {
      title: "Proactive Insights",
      id: "proactive-insights",
      description:
        "Utilize real-time monitoring and predictive analysis to receive timely insights on market changes, consumer behavior, and marketplace trends. This will enable you to make proactive decisions to stay ahead.",
    },
    {
      title: "Comprehensive Reporting",
      id: "comprehensive-reporting",
      description:
        "Whether it's for in-depth weekly analyses or concise monthly summaries, plan for the future and understand your brand’s evolution through AI-generated reports and recommendations.",
    },
  ],
};
// Listing Optimization
export const listingOptimizationDetails1 = {
  image: "https://i.ibb.co/1mPm8zj/KEYWORDOPTIMIZATION.png",
  mobileImage: "https://i.ibb.co/GFQ1nJx/KEYWORDOPTIMIZATION-2.png",
  title: "KEYWORD OPTIMIZATION",
  description:
    "Enhance visibility and drive sales with our strategic keyword optimization, helping you stand out in a competitive marketplace.",
  lists: [
    {
      title: "Targeted Keyword Research",
      id: "targeted-keyword-research",
      description:
        "Through our research tools, you can target high-impact keywords relevant to your audience and products and find competitor keywords to outrank them.",
    },
    {
      title: "Strategic Keyword Integration",
      id: "strategic-keyword-integration",
      description:
        "Increase the likelihood of showing up in search results and drawing in new customers by creating unique product titles and optimizing descriptions with relevant keywords.",
    },
    {
      title: "Continuous Optimization",
      id: "continuous-optimization",
      description:
        "Make adjustments and continuously refine your optimization approach by keeping a close eye on market trends and keyword performance.",
    },
    {
      title: "Actionable Insights",
      id: "actionable-insights",
      description:
        "To improve outcomes, use data-driven advice to optimize your keyword strategy and monitor the effects of your efforts on search ranks, CTR, and conversions.",
    },
  ],
};
export const listingOptimizationDetails2 = {
  image: "https://i.ibb.co/QvctdY1/TITLEOPTIMIZATION.png",
  mobileImage: "https://i.ibb.co/Mnn2QvL/TITLEOPTIMIZATION-2.png",
  title: "TITLE OPTIMIZATION",
  description:
    "Make your Amazon listings stand out, attract more customers, and drive higher conversion rates to drive your brand’s visibility and success.",
  lists: [
    {
      title: "Strategic Keyword Integration",
      id: "strategic-keyword-integration",
      description:
        "Create headlines rich with relevant keywords to boost search engine rankings and draw in targeted readers, all while maintaining readability and keeping necessary information.",
    },
    {
      title: "Clarity and Conciseness",
      id: "clarity-and-conciseness",
      description:
        "Make sure your product titles are precise and educational. Make the right use of character constraints to draw in clients without going overboard.",
    },
    {
      title: "Brand Identity and Differentiation",
      id: "brand-identity-and-differentiation",
      description:
        "Include your brand name in titles to increase brand identification and trust. Highlight special features to stand out from the competition and draw attention.",
    },
    {
      title: "Continuous Optimization and Performance Tracking",
      id: "continuous-optimization-and-performance-tracking",
      description:
        "Track keyword ranks, refine titles, and improve click-through rates and conversion rates, to keep titles competitive.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Increase your visibility and sales through data-driven insights and recommendations. Our tools will help in A/B testing and determine which titles work best.",
    },
  ],
};
export const listingOptimizationDetails3 = {
  image: "https://i.ibb.co/DkcngFW/DESCRIPTIONENCHANCEMENT.png",
  mobileImage: "https://i.ibb.co/wCG9rDf/DESCRIPTIONENCHANCEMENT-2.png",
  title: "DESCRIPTION ENCHANCEMENT",
  description:
    "Have control over your Amazon pricing strategy, ensuring brand integrity and profitable pricing across all channels.",
  lists: [
    {
      title: "Informative Content",
      id: "informative-content",
      description:
        "Get clear and detailed descriptions of your product descriptions with specifications, features, and usage guidance to reduce bounce back rates and returns.",
    },
    {
      title: "Persuasive Messaging",
      id: "persuasive-messaging",
      description:
        "Use engaging words to pinpoint unique selling points and connect emotionally with the audience by addressing their needs and wants.",
    },
    {
      title: "Visual Enhancement",
      id: "visual-enhancement",
      description:
        "Improve your readability with formatting such as with the use of bullet points, bold text, and headers. Use high-quality images and showcase them from different angles as well.",
    },
    {
      title: "Search Optimization",
      id: "search-optimization",
      description:
        "Use the relevant keywords in your descriptions and use structured data makeup to enhance search visibility and increase organic traffic.",
    },
    {
      title: "Continuous Improvement",
      id: "continuous-improvement",
      description:
        "Continuously refine descriptions based on feedback, performance data, and market trends. Utilize the power of A/B testing to choose the best product descriptions.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Get better click-through rates, conversions, and customer engagement by analyzing performance metrics, getting recommendations, and optimizing descriptions.",
    },
  ],
};
export const listingOptimizationDetails4 = {
  image: "https://i.ibb.co/d2qHNHs/IMAGEOPTIMIZATION.png",
  mobileImage: "https://i.ibb.co/6cMVgGy/BRANDPOLICYCOMPLIANCE-4.png",
  title: "IMAGE OPTIMIZATION",
  description:
    "Create stunning visuals that captivate customers and drive conversions on Amazon, enhancing your brand's presence and listing.",
  lists: [
    {
      title: "High-Quality Imagery",
      id: "high-quality-imagery",
      description:
        "Show your products from multiple angles and capture picture-perfect images that accurately represent your product to give customers a comprehensive view.",
    },
    {
      title: "Enhanced Presentation",
      id: "enhanced-presentation",
      description:
        "Our tools remove the clutter from your product images and produce clean backgrounds to make products your product stand out and help customers visualize everyday use.",
    },
    {
      title: "Compliance with Amazon Guidelines",
      id: "compliance-with-amazon-guidelines",
      description:
        "Ensure images meet Amazon's size, format, and content guidelines, and optimize the main image for maximum visibility and customer attention.",
    },
    {
      title: "Brand Consistency",
      id: "brand-consistency",
      description:
        "Incorporate your logo and watermark to reinforce brand identity, maintain consistent image style, and branding, and build trust.",
    },
    {
      title: "A/B Testing and Optimization",
      id: "a-b-testing-and-optimization",
      description:
        "Find the most effective images, analyze click-through rates, and increase conversion rates for continuous improvement and refinement.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Incorporate the best practices for composition, lighting, and presentation into your strategy. Use our performance insights and actionable recommendations to optimize product images.",
    },
  ],
};
export const listingOptimizationDetails5 = {
  image: "https://i.ibb.co/qxY27W6/ABTESTING.png",
  mobileImage: "https://i.ibb.co/D507QNr/BRANDPOLICYCOMPLIANCE-3.png",
  title: "A/B TESTING",
  description:
    "Unlock valuable insights for optimizing Amazon listings and improving sales performance and identity the most effective elements for conversations.",
  lists: [
    {
      title: "Testing Variations",
      id: "test-variations",
      description:
        "Drive the highest conversion rates by experimenting with different title formats, image composition, and description styles to attract your target audience.",
    },
    {
      title: "Data-Driven Insights",
      id: "data-driven-insights",
      description:
        "Our statistical analysis measures click-through rates, conversion rates, and sales to evaluate the effectiveness of each variation and make informed decisions.",
    },
    {
      title: "Continuous Optimization",
      id: "continuous-optimization",
      description:
        "Regular refinement of your listings based on A/B testing insights and performance monitoring will help you adapt to changes in market trends.",
    },
    {
      title: "Actionable Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Optimize your listings effectively through actionable recommendations from A/B testing results and guidance on rest design, sample size, and result interpretation.",
    },
  ],
};

// seller Central Management
export const sellerCentralManagementDetails1 = {
  image: "https://i.ibb.co/VCjbm7p/SHIPMENTCREATION.jpg",
  mobileImage: "https://i.ibb.co/mN6kNmk/SHIPMENTCREATION.png",
  title: "SHIPMENT CREATION",
  description:
    "Streamline logistics operations, optimizing order fulfillment for exceptional customer experiences on Amazon to unlock efficiency.",
  lists: [
    {
      title: "Efficient Order Fulfillment",
      id: "efficient-order-fulfillment",
      description:
        "Create shipments for multiple orders in a single batch to save time and reduce manual effort. Automatically generate shipping labels for each shipment to eliminate errors and streamline the fulfillment process.",
    },
    {
      title: "Optimized Inventory Management",
      id: "optimized-inventory-management",
      description:
        "Allocate inventory to shipments based on priority and real-time tracking. Monitor inventory levels and receive alerts for low-stock items to prevent stockouts and ensure efficient utilization.",
    },
    {
      title: "Streamlined Logistics Operations",
      id: "streamlined-logistics-operations",
      description:
        "Integrate with leading carriers for streamlined shipping and access to discounted rates. Customize shipping settings, including methods and handling fees, to meet specific business and customer needs.",
    },
    {
      title: "Enhanced Visibility and Tracking",
      id: "enhanced-visibility-and-tracking",
      description:
        "Track shipment statuses in real-time and provide customers with accurate delivery estimates and tracking information. Receive delivery confirmations and monitor feedback for a positive post-purchase experience.",
    },
    {
      title: "Actionable Insights and Reporting",
      id: "actionable-insights-and-reporting",
      description:
        "Gain insights into shipment metrics and generate customized reports on shipment history and performance trends. Use analytics to optimize costs and inform strategic decision-making.",
    },
  ],
};
export const sellerCentralManagementDetails2 = {
  image: "https://i.ibb.co/KLjckKt/LISTINGCREATION.jpg",
  mobileImage: "https://i.ibb.co/tmbRM9q/LISTINGCREATION.png",
  title: "LISTING CREATION",
  description:
    "Craft compelling product listings that attract customers, drive conversions, and maximize sales on Amazon.",
  lists: [
    {
      title: "Intuitive Listing Builder",
      id: "intuitive-listing-builder",
      description:
        "Our user-friendly interface allows you to easily create new product listing with step-by-step guidance so that you give all the essential information or optimal search and conversation.",
    },
    {
      title: "Rich Product Content",
      id: "rich-product-content",
      description:
        "Write attractive and detailed product descriptions that highlight key features and benefits, and upload high-resolution images to showcase your products from various angles.",
    },
    {
      title: "Optimized Search Visibility",
      id: "optimized-search-visibility",
      description:
        "Incorporate relevant keywords into your titles, descriptions, and bullet points to irmpove search visibility. Use structured markup to improve product visibility in the search results.",
    },
    {
      title: "Customizable Attributes",
      id: "customizable-attributes",
      description:
        "Intice customer to buy by setting flexible pricing, including discounts and promotions, and manage product variations like size, color, and style within a single listing.",
    },
    {
      title: "Compliance and Quality Assurance",
      id: "compliance-and-quality-assurance",
      description:
        "Make sure that your listing adhere to Amazon’s guidelines and conduct quality assurance to verify completeness, accuracy, and optimization for highest performance.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Based on data, customer feedback, and market trends, we suggest the best optimization tactics, and allow you to monitor listing performance with analytics on CTR, conversion rates and sales metrics.",
    },
  ],
};
export const sellerCentralManagementDetails3 = {
  image: "https://i.ibb.co/JxCfp1x/CASERESOLUTION.jpg",
  mobileImage: "https://i.ibb.co/D7NpKf7/CASERESOLUTION.png",
  title: "CASE RESOLUTION",
  description:
    "Stockyfy's Amazon Case Resolution tools enable sellers to swiftly address customer inquiries, ensuring a positive shopping experience and building loyalty.",
  lists: [
    {
      title: "Efficient Case Management",
      id: "efficient-case-management",
      description:
        "Keep a track of everything using centralized dashboards to manage customer cases, including refunds, returns and inquiries, and prioritize cases based on urgency to ensure swift resolutions.",
    },
    {
      title: "Clear Communication Channels",
      id: "clear-communication-channels",
      description:
        "Address complaints and concerns quickly through Amazon’s messaging system. This allows for template responses for common issues to steamline and save quality time.",
    },
    {
      title: "Transparent Resolution Process",
      id: "transparent-resolution-process",
      description:
        "Document actions and outcomes, and track case progress from initiation to resolution with regular updates for a comprehensive record of interactions and resolutions.",
    },
    {
      title: "Expert Guidance and Support",
      id: "expert-guidance-and-support",
      description:
        "Get the best of both worlds with Stockyfy’s e-commerce experts and Amazon’s seller support for resolving and navigating complex cases and optimizing strategies.",
    },
    {
      title: "Proactive Issue Prevention",
      id: "proactive-issue-prevention",
      description:
        "Take proactive prevention through root cause analysis to address recurring issues and implement continuous improvement statetegies to improve the quality of customer service.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Monitor and receive tailored recommendations on metrics like response time and customer satisfaction using data to optimize the resolution process.",
    },
  ],
};
export const sellerCentralManagementDetails4 = {
  image: "https://i.ibb.co/bNWq6NB/BRANDREGISTRY.jpg",
  mobileImage: "https://i.ibb.co/5hW1KST/BRANDREGISTRY.png",
  title: "BRAND REGISTRY",
  description:
    "Protect your brand and enforce intellectual property rights on the Amazon marketplace to safeguard brand integrity and achieve business goals.",
  lists: [
    {
      title: "Trademark Protection",
      id: "trademark-protection",
      description:
        "Reduce counterfeit risk and verify your brand identity by enrolling your brand’s trademark in Amazon’s Brand Registry for enhanced protection and security.",
    },
    {
      title: "Enhanced Brand Content",
      id: "enhanced-brand-content",
      description:
        "Provide an enriched and engaging shopping experience through custom brand storefronts and A+ content, including multimedia elements and detailed product pages.",
    },
    {
      title: "Brand Protection and Enforcement",
      id: "brand-protection-and-enforcement",
      description:
        "Protect your brand from conterfeits and unauthorized sellers through brand gating. Restrict third-party sellers from listing your products without authorization.",
    },
    {
      title: "Brand Insights and Analytics",
      id: "brand-insights-and-analytics",
      description:
        "Make the best strategic decision by using and monitoring KPIs such as sales, traffic, customer activity, and customer engagement rates to asses your brand health.",
    },
    {
      title: "Proactive Brand Management",
      id: "proactive-brand-management",
      description:
        "Develop proactive strategies switch as for monitoring and enforcement, and establish clear brand guidelines to maintain consistence and safeguard your brand’s integrity and reputation.",
    },
    {
      title: "Actionable Insights and Recommendations",
      id: "actionable-insights-and-recommendations",
      description:
        "Get detailed reports on brand health, including performance trends and infringement to optimize brand protective and improve brand value on Amazon.",
    },
  ],
};
