import { HashLink } from "react-router-hash-link";

function GrowFaster() {
  return (
    <div className="mx-10 lg:mx-40">
      <p className="my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
        Grow Faster. Reach Higher
      </p>
      <div className="flex flex-col items-center bg-white md:flex-row md:max-w-full">
        <img
          className="hidden md:block object-cover w-auto max-h-[400px] 2xl:max-h-[548px]"
          src="https://i.ibb.co/s9sBS3H/Rectangle-12.png"
          alt=""
        />
        <div className="md:ml-20 flex flex-col justify-between md:p-4 leading-normal">
          <h5 className="z-20 mb-4 md:mb-2 text-[24px] md-text-[30px] xl:text-[40px] font-bold text-[#1E1E1E] max-w-[640px] ">
            The Ultimate Partner In Your Ecommerce Success Story
          </h5>
          <p className="z-20 mb-7 font-normal text-[#1E1E1E] max-w-[640px]">
            From optimizing product listings to crafting winning marketing
            strategies, Stockyfy amplifies your brand's presence on Amazon,
            helping you reach more customers and drive sales growth.
          </p>
          <HashLink className="z-20 " smooth to="/contact">
            <button className="transition-all duration-500 hover:text-[#000000] text-[#ffffff] hover:bg-transparent  bg-[#FF7927] border-[#FF7927] border hover:border-[#000000] font-manrope text-sm xl:text-xl font-normal text-[#ffffff] md:w-auto w-full py-2 xl:py-4 md:px-11 mb-4 md:mb-0 rounded-full items-center ">
              <span className="mr-4">Schedule a Meeting</span>
              <span aria-hidden="true">&rarr;</span>
            </button>
          </HashLink>
        </div>
        <img
          className=" rounded-[10px] block md:hidden object-cover w-full max-h-[240px] sm:max-h-[340px]"
          src="https://i.ibb.co/s9sBS3H/Rectangle-12.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default GrowFaster;
