import WantToLearnMore from "../banners/wantToLearnMore";
import Footer from "../footer/footer";
import Header from "../header/header";
import Aboutus from "../../assets/images/Aboutus.png";
import OurStory from "../banners/ourStory";
import OurValue from "../banners/ourValue";
import OurVision from "../banners/ourVision";
import WeOffer from "../banners/weOffer";
import OurJurney from "../banners/ourJurney";
import { useLayoutEffect } from "react";

function AboutUsPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <section className="mx-10 lg:mx-40 mb-4">
        <p className="my-4 md:my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
          About Us
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-[26px]  xl:text-[36px] 2xl:text-[48px] text-[#1E1E1E] font-bold">
            Making A Difference Beyond Borders and Limits
          </div>
          <div className="flex justify-start md:text-left md:justify-end text-[16px] xl:text-xl text-[#1E1E1E] font-normal">
            <p className="max-w-full md:max-w-[490px]">
              Our story goes way beyond selling products. Since 2016, we have
              helped brands grow on Amazon with every click and customer review
              - and we’re just getting started.{" "}
            </p>
          </div>
        </div>
      </section>
      <img className="object-fill h-4/5 w-full" alt="About us" src={Aboutus} />
      <OurStory />
      <OurValue />
      <OurVision />
      <WeOffer />
      <OurJurney />
      <WantToLearnMore />

      <Footer />
    </div>
  );
}

export default AboutUsPage;
