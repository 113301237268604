import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import ECommerceMarketplace from "../../assets/images/ECommerceMarketplace.png";
import Footer from "../footer/footer";
import Header from "../header/header";
import HowItWorks from "../banners/howItWorks";
import KeyFeatures from "../banners/keyFeatures";
import { useLayoutEffect } from "react";

function ECommerceMarketplaceManagement() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <section className="mx-10 lg:mx-40 mb-4">
        <p className="my-4 md:my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
          Brand Launch
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-[26px] lg:text-[36px] xl:text-[48px] 2xl:text-[64px] text-[#1E1E1E] font-bold">
            E-Commerce Marketplace Management
          </div>
          <div className="flex justify-start md:text-left md:justify-end text-[16px] xl:text-xl text-[#1E1E1E] font-normal">
            <p className="max-w-full md:max-w-[490px]">
              Optimize insights and reporting, protect brands, enhance listings,
              and manage seller operations for a competitive e-commerce
              marketplace advantage.{" "}
            </p>
          </div>
        </div>
      </section>
      <img
        className="object-fill h-4/5 w-full"
        alt="ECommerceMarketplace"
        src={ECommerceMarketplace}
      />
      <KeyFeatures />
      <HowItWorks eCommerceBrands={true} />
      <ECommerceBrands />
      <WantToLearnMore />

      <Footer />
    </div>
  );
}

export default ECommerceMarketplaceManagement;
