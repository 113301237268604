function OurVision(props) {
  return (
    <section className="mt-16 pt-20 bg-[#F5F5FC] ">
      <div className="items-center">
        <hr className="z-10 mx-10 lg:mx-40 h-0.5 bg-[#000000]" />
      </div>
      <div className=" mx-10 lg:mx-40 ">
        <p className="my-4 md:my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
          Vision
        </p>
        <div className="grid grid-cols-1 md:gap-8 md:grid-cols-7">
          <div className=" content-center text-white  md:col-span-3">
            <div className="text-[24px] lg:text-[32px] xl:text-[40px] text-[#1E1E1E] font-bold">
              Our Vision
            </div>
            <div className="mb-4 md:mb-0 mt-4 lg:mt-8 text-[14px] lg:text-[18px] xl:text-[20px] text-[#1E1E1E] font-normal">
              <p className="">
                Our vision is to create a sustainable technological ecosystem
                where individuals can realize their full potential.
              </p>
            </div>
          </div>
          <div className="content-center pb-8 text-white md:col-span-4 ">
            {" "}
            <img
              src="https://i.ibb.co/gMsHrhF/OURVISION.png"
              alt="OURVISION"
              className="w-full h-full rounded-3xl col-span-2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurVision;
