import { HashLink } from "react-router-hash-link";

function WorkWithUsSection() {
  return (
    <div className="relative isolate bg-cover h-[240px] md:h-[366px]  bg-[url('https://i.ibb.co/XVrxFdB/Work-With-Us-Background.gif')] bg-no-repeat flex items-center justify-center">
      <div className="mx-auto flex max-w-4xl ">
        <div className="text-center align-middle inline-block">
          <p className="tracking-wider text-2xl font-bold  text-[#ffffff] leading-tight sm:text-4xl md:text-5xl lg:text-[64px]">
            We’re The Spark That Sets Your Success in Motion.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <HashLink smooth to="/contact">
              <button className=" transition-all duration-500 hover:text-[#000000] text-[#ffffff] hover:bg-white  bg-[#FF7927] border-[#FF7927] border hover:border-[#000000] font-manrope text-16px md:text-xl font-medium text-[#ffffff] py-2 px-20 md:py-4 md:px-11 rounded-full sm:inline-flex items-center">
                <span className="mr-4">Schedule a Meeting</span>
                <span className="hidden sm:block" aria-hidden="true">
                  &rarr;
                </span>
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkWithUsSection;
