import "@fontsource/manrope";
import "./App.css";
// import React form "react";
import { Route, Routes } from "react-router-dom";
import AboutUsPage from "./app/components/pages/aboutusPage";
import ContactUsPage from "./app/components/pages/contactusPage";
import HomePage from "./app/components/pages/homePage";
import InsightAndReporting from "./app/components/pages/insightAndReporting";
import BrandsProtection from "./app/components/pages/brandsProtection";
import ECommerceMarketplaceManagement from "./app/components/pages/e-CommerceMarketplaceManagement";
import ListingOptimization from "./app/components/pages/listingOptimization";
import EndToEndLogisticsManagement from "./app/components/pages/endToEndLogisticsManagement";
import MarketingCampaigns from "./app/components/pages/marketingCampaigns";
import OnDemandService from "./app/components/pages/onDemandService";
import SellerCentralManagement from "./app/components/pages/sellerCentralManagement";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about" element={<AboutUsPage />} />
      <Route exact path="/contact" element={<ContactUsPage />} />
      <Route
        exact
        path="/insight-and-reporting"
        element={<InsightAndReporting />}
      />
      <Route exact path="/brands-protection" element={<BrandsProtection />} />
      <Route
        exact
        path="/listing-optimization"
        element={<ListingOptimization />}
      />
      <Route
        exact
        path="/marketing-campaigns"
        element={<MarketingCampaigns />}
      />
      <Route exact path="/on-demand-service" element={<OnDemandService />} />
      <Route
        exact
        path="/seller-central-management"
        element={<SellerCentralManagement />}
      />
      <Route
        exact
        path="/e-commerce-marketplace-management"
        element={<ECommerceMarketplaceManagement />}
      />
      <Route
        exact
        path="/end-to-end-logistics-management"
        element={<EndToEndLogisticsManagement />}
      />
    </Routes>
  );
}

export default App;
