import { useNavigate } from "react-router-dom";

function WantToLearnMore() {
  const navigate = useNavigate();
  return (
    <section className="px-[10px] bg-[#F5F5FC] py-8 md:py-20">
      {/* <div className="flex-grow border-t border-gray-900"></div> */}
      <div className=" flex items-center justify-center ">
        <p className="text-[24px] md:text-[40px]  text-[#1E1E1E] font-bold">
          Unlock Your E-Commerce Possibilities.
        </p>
      </div>
      <div className=" flex items-center text-center justify-center ">
        <p className="text-[16px] md:text-[20px]  text-[#1E1E1E] font-normal">
          Complete the form and member of our team will reach out.
        </p>
      </div>
      <div className="mt-4 md:mt-8 flex items-center justify-center ">
        <button
          onClick={() => {
            navigate("/contact");
          }}
          className="w-[320px] md:w-[460px] text-white hover:text-black hover:border hover:border-[#000000]  bg-[#FF7927] hover:bg-transparent font-medium rounded-full text-base px-8 py-3 transition-all duration-500 "
        >
          Connect Us Now!
        </button>
      </div>
    </section>
  );
}

export default WantToLearnMore;
