import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function OurService() {
  const services = [
    {
      title: "E-Commerce Marketplace Management",
      description:
        "Optimize your e-commerce marketplace for increased sales, customer satisfaction, and increased brand visibility.",
      image: "https://i.ibb.co/30PhnVP/Group-37.png",
      url: "/e-commerce-marketplace-management",
    },
    {
      title: "End-to-End Logistics",
      description:
        "Streamline logistics operations, optimizing order fulfillment for exceptional customer experiences on Amazon to unlock efficiency.",
      image: "https://i.ibb.co/16Ln3P9/End-To-End-Logistics.png",
      url: "/end-to-end-logistics-management",
    },
    {
      title: "Marketing Campaigns",
      description:
        "Maximize your brand's visibility with expertly managed PPC and meta campaigns, and develop a robust marketing strategy tailored to your unique needs.",
      image: "https://i.ibb.co/z6yRC5N/Marketing-Campaigns.png",
      url: "marketing-campaigns",
    },
    {
      title: "On-Demand Services",
      description:
        "Ensure accurate stock records with our inventory reconciliation services and swiftly resolve issues to get your Amazon account back on track with our expert account reinstatement.",
      image: "https://i.ibb.co/Ss6tPFK/On-Demand-Service.png",
      url: "on-deman-service",
    },
  ];
  const [width, setWidth] = useState(window.innerWidth);
  // console.log("🚀 ~ OurService ~ width:", width);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const animationElements = document.querySelectorAll(".animation-element");
    const handleScrollOrResize = () => {
      const windowHeight = window.innerHeight;
      const windowTopPosition = window.scrollY;
      const windowBottomPosition = windowHeight + windowTopPosition;

      animationElements.forEach((element) => {
        const elementHeight = element.offsetHeight;
        const elementTopPosition = element.offsetTop;
        const elementBottomPosition = elementHeight + elementTopPosition;

        if (
          elementBottomPosition >= windowTopPosition &&
          elementTopPosition <= windowBottomPosition
        ) {
          element.classList.add("in-view");
        } else {
          element.classList.remove("in-view");
        }
      });
    };

    window.addEventListener("scroll", handleScrollOrResize);
    window.addEventListener("resize", handleScrollOrResize);

    handleScrollOrResize();

    return () => {
      window.removeEventListener("scroll", handleScrollOrResize);
      window.removeEventListener("resize", handleScrollOrResize);
    };
  }, []);

  const cardsContainerRef = useRef(null);
  const cardsRef = useRef([]);

  const handleScroll = () => {
    const cards = cardsRef.current;
    cards.forEach((card, index) => {
      const nextCard = cards[index + 1];
      if (!nextCard) return;
      const cardInner = card.querySelector(".card__inner1");
      const cardRect = card.getBoundingClientRect();
      const nextCardRect = nextCard.getBoundingClientRect();
      const offsetTop = 20 + index * 20;
      const percentageY = Math.max(
        0,
        Math.min(
          1,
          (window.innerHeight - nextCardRect.top + offsetTop) / cardRect.height
        )
      );

      const toScale = 1 - (cards.length - 1 - index) * 0.1;
      cardInner.style.transform = `scale(${1 - percentageY * (1 - toScale)})`;
      cardInner.style.filter = `brightness(${1 - percentageY * 0.4})`;
    });
  };

  useEffect(() => {
    const cardsContainer = cardsContainerRef.current;
    const cards = cardsRef.current;
    cardsContainer.style.setProperty("--cards-count", cards.length);
    // cardsContainer.style.setProperty(
    //   "--card-height",
    //   `${cards[0].clientHeight}px`
    // );
    cards.forEach((card, index) => {
      const offsetTop = width < 768 ? 20 : 150 + index * 20;
      card.style.paddingTop = `${offsetTop}px`;
    });

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <section className="bg-[#DEDEF64A]">
      <div className="mt-8 py-8 md:mt-20 md:py-20 mx-5 lg:mx-20 xl:mx-40">
        <hr className="z-10 h-0.5 bg-[#000000]" />
        <p className="my-5 text-[12px] md:text-base uppercase font-normal text-[#000000]">
          Our Services
        </p>
        <div className="mb-10 grid grid-cols-1 lg:grid-cols-2 gap-4 items-center">
          <div className="flex justify-center text-center lg:justify-start lg:text-left lg:max-w-[600px] text-[24px] lg:text-[40px] text-[#1E1E1E] font-bold">
            Boost Your Brand with Expert Amazon Solutions
          </div>
          <div className="flex justify-center text-center lg:justify-end  lg:text-left text-lg lg:text-xl text-[#1E1E1E] font-normal">
            <p className="max-w-[490px]">
              Turn your brand into an unstoppable force with our Amazon
              Accelerator Services.
            </p>
          </div>
        </div>
        <div>
          <div className="cards" ref={cardsContainerRef}>
            {services.map((service, index) => (
              <div
                className="card"
                data-index={index}
                ref={(el) => (cardsRef.current[index] = el)}
                key={index}
              >
                <div className="card__inner1">
                  <div className="">
                    <div className="box box1 grid grid-cols-1 md:grid-cols-9 md:gap-4">
                      <div className="h-full md:flex md:items-center mb-10 2xl:mb-0 px-5 xl:px-10 2xl:px-20 bg-white rounded-3xl md:col-span-4 lg:col-span-3">
                        <span>
                          <div className="py-5 flex justify-center block md:hidden">
                            <img
                              src={service.image}
                              alt="Our Services"
                              className={`w-auto h-auto rounded-3xl `}
                            />
                          </div>
                          <div className="flex justify-center md:justify-start ">
                            <p
                              onClick={() => {
                                navigate(service.url);
                              }}
                              className="hover:underline cursor-pointer xl:px-0 px-3 text-[20px] lg:text-[25px] text-center md:text-left font-bold "
                            >
                              {service.title}
                            </p>
                          </div>
                          <div className="">
                            <p className="mt-4 xl:mt-6 xl:px-0 px-3 text-base lg:text-lg leading-6 text-center md:text-left lg:leading-8  ">
                              {service.description}
                            </p>
                          </div>
                          {/* <div className=""> */}
                          <HashLink
                            smooth
                            className=" md:flex md:justify-center"
                            to="/contact"
                          >
                            <button className=" mt-10 hover:text-[#000000] text-[#ffffff] hover:bg-transparent  bg-[#FF7927] border-[#FF7927] border hover:border-[#000000]  font-manrope text-base font-normal leading-6 w-full md:w-auto u md:flex md:justify-centerppercase py-2.5 md:px-7 rounded-full ">
                              Schedule a Meeting
                            </button>
                          </HashLink>
                        </span>
                        {/* </div> */}
                      </div>
                      <div className="hidden md:block md:col-span-5 lg:col-span-6">
                        <img
                          src={service.image}
                          alt="Our Services"
                          className={`w-full h-full rounded-3xl `}
                        />
                      </div>
                    </div>
                    {/* <div className="box box1">
                <span className="span">{i + 1}</span>
              </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* {services.map((service, i) => (
            <div className="animation-element bounce-up" key={i}>
              <div className="box box1 grid grid-cols-1 md:grid-cols-9 md:gap-4">
                <div className="h-full md:flex md:items-center mb-10 2xl:mb-0 px-5 xl:px-10 2xl:px-20 bg-white rounded-3xl md:col-span-4 lg:col-span-3">
                  <span>
                    <div className="py-5 flex justify-center block md:hidden">
                      <img
                        src={service.image}
                        alt="Our Services"
                        className={`w-auto h-auto rounded-3xl `}
                      />
                    </div>
                    <div className="flex justify-center md:justify-start ">
                      <p className=" xl:px-0 px-3 text-[20px] lg:text-[25px] text-center md:text-left font-bold ">
                        {service.title}
                      </p>
                    </div>
                    <div className="">
                      <p className="mt-4 xl:mt-6 xl:px-0 px-3 text-base lg:text-lg leading-6 text-center md:text-left lg:leading-8  ">
                        {service.description}
                      </p>
                    </div>
                    <HashLink
                      smooth
                      className=" md:flex md:justify-center"
                      to="/e-commerce-marketplace-management#key-features"
                    >
                      <button className=" mt-10 hover:text-[#000000] text-[#ffffff] hover:bg-transparent  bg-[#FF7927] border-[#FF7927] border hover:border-[#000000]  font-manrope text-base font-normal leading-6 w-full md:w-auto u md:flex md:justify-centerppercase py-2.5 md:px-7 rounded-full ">
                        Work With Us
                      </button>
                    </HashLink>
                  </span>
                </div>
                <div className="hidden md:block md:col-span-5 lg:col-span-6">
                  <img
                    src={service.image}
                    alt="Our Services"
                    className={`w-full h-full rounded-3xl `}
                  />
                </div>
              </div>
            </div>
          ))}*/}
        </div>
      </div>
      {/* <div className="App">
       */}

      {/* </div> */}
    </section>
  );
}

export default OurService;
