import WantToLearnMore from "../banners/wantToLearnMore";
import ECommerceBrands from "../brands/brands";
import Footer from "../footer/footer";
import Header from "../header/header";
import EndToEndLogistics from "../../assets/images/EndToEndLogistics.png";
import ContentDetail from "../banners/contentDetail";
import HowItWorks from "../banners/howItWorks";
import { useLayoutEffect } from "react";
function EndToEndLogisticsManagement() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <div className="bg-[#F5F5FC]">
        <section className="mx-10 lg:mx-40 py-10 md:py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="text-[26px]  xl:text-[36px] 2xl:text-[48px] text-[#1E1E1E] font-bold">
              END-TO-END LOGISTICS MANAGEMENT
            </div>
            <div className="flex justify-start md:text-left md:justify-end text-[16px] xl:text-xl text-[#1E1E1E] font-normal">
              <p className="max-w-full md:max-w-[490px]">
                Streamline logistics operations, optimizing order fulfillment
                for exceptional customer experiences on Amazon to unlock
                efficiency.{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
      <img
        className="object-fill h-4/5 w-full"
        alt="EndToEndLogistics"
        src={EndToEndLogistics}
      />
      <ContentDetail
        margin={true}
        title="Shipping Services"
        id="shipping-services"
        detail="Get the best shipping services with Stockyfy. We provide efficient, reliable, and streamlines shopping services to match your brand’s needs. From order fulfillment to delivery, we make sure that your product reach your customers on time and damage-free."
      />
      <ContentDetail
        margin={true}
        title="Warehousing"
        id="warehousing"
        detail="Get scalable and safe warehouse options for storing your inventory. Our cutting-edge facilities offer the best storage conditions, guaranteeing that your products are always prepared for dispatch."
      />
      <ContentDetail
        margin={true}
        title="Inventory Management"
        id="inventory-management"
        detail="Keep track of your stock seamlessly without our state-of-the art inventory management system. We ensure you maintain optimal stock levels, all while reducing overhead costs and preventing stockouts so that your business runs smoothly."
      />
      <ContentDetail
        margin={true}
        title="Packaging and Labelling"
        id="packaging-labelling"
        detail="Save time the profesisonal packaging and accurate labelling service to improve your brand’s image and ensure compliance with Amazon’s guidelines. We take care of the small details so that you can focus on the growth success of your business."
      />
      <HowItWorks eCommerceBrands={false} />
      <ECommerceBrands />
      <WantToLearnMore />
      <Footer />
    </div>
  );
}

export default EndToEndLogisticsManagement;
